import { Form, Formik, FormikHelpers } from "formik";

import { Button } from "antd";
import InputField from "../../../../shared/components/InputField";
import { InputType } from "../../../../enums/input.type";
import React, { useEffect, useState } from "react";
import SelectInput from "../../../../shared/components/SelectInput";

import useBranch from "../../../../shared/hooks/useBranch";
import { DisbursementModel } from "../../../../models/disbursement.model";
import { Process } from "../../../../enums/process.enum";
import { MetaService } from "../../../../services/Meta/meta.service";
import { Meta } from "../../../../models/meta.modal";
import { LoanService } from "../../../../services/Loan/loan.service";

import { Loan } from "../../../../models/loan.model";

import { disbursementAmountValidation } from "./disbursementAmountValidation";
interface DisburseAmountFormProps {
  toggleDisburseDrawer: () => void;
  onSuccess: (loans: Loan[], formValues: DisbursementModel) => void;
  setSelectedLoans: React.Dispatch<React.SetStateAction<number[]>>
}
function DisburseAmountForm(props: DisburseAmountFormProps) {
  const { toggleDisburseDrawer, onSuccess, setSelectedLoans} = props;
  const [loanTypesOptions, setLoanTypeOptions] = useState<Meta[]>([]);
  const { disburseLoanAmount } = LoanService();
  const {
    loading: branchesLoading,
    branches,
    handleBranchScroll,
    handleBranchSearch,
  } = useBranch({});

  const processOptions = [
    { label: "Automatic", value: Process.AUTOMATIC },
    { label: "Manual", value: Process.MANNUAL },
  ];
  const { getLoanTypes } = MetaService();

  const fetchLoanTypes = async () => {
    const data = await getLoanTypes();
    data && setLoanTypeOptions(data);
  };

  useEffect(() => {
    fetchLoanTypes();
  }, []);

  const handleSubmit = async (
    values: DisbursementModel,
    helpers: FormikHelpers<any>
  ) => {
    const result = await disburseLoanAmount(values);
    if (result) {
      onSuccess(result?.loans, values);
      toggleDisburseDrawer();
      setSelectedLoans(result?.loans?.map((loan) => loan?.id).filter((id): id is number => id !== undefined));
    }
  };

  return (
    <div className="disburse-form">
      <Formik
        initialValues={new DisbursementModel()}
        onSubmit={handleSubmit}
        validationSchema={disbursementAmountValidation}
      >
        {({ dirty, isValid }) => (
          <Form className="ant-form-vertical disburse-form">
            <InputField
              type={InputType.NUMBER}
              name="disburseAmount"
              placeholder="Enter Disbursement Amount"
              label="Disburse Amount"
            />
            <SelectInput
              name="process"
              options={processOptions}
              showSearch
              placeholder="Select Process"
              label="Process"
            />{" "}
            <SelectInput
              name="branchIds"
              options={branches}
              showSearch
              handleSearch={handleBranchSearch}
              placeholder="Select Branch"
              label="Branch"
              mode="multiple"
              onScroll={handleBranchScroll}
              loading={branchesLoading}
            />
            <SelectInput
              name="loanTypeIds"
              options={loanTypesOptions}
              showSearch
              mode="multiple"
              placeholder="Select Loan Type"
              label="Loan Type"
            />{" "}
            <InputField
              type={InputType.NUMBER}
              name="maximumLoanAmount"
              placeholder="Enter Maximum Loan Amount"
              label="Maximum Loan Amount"
            />
            <div className="drawer-footer">
              <Button
                htmlType="reset"
                onClick={toggleDisburseDrawer}
                type="link"
                className="drawer-footer__cancel"
              >
                Cancel
              </Button>
              <Button
                disabled={!dirty || !isValid}
                htmlType="submit"
                className="drawer-footer__submit"
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default DisburseAmountForm;
