/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import useQueryParams from '../../../shared/hooks/useQueryParams';
import { LoanParams } from '../../../models/loan.model';
import { TabsProps } from '../../../shared/types/Tabs.type';
import ApprovedLoans from '../ApprovedLoans';
import DisbursementInProgress from '../DisbursementLoans';
import { Tabs } from 'antd';
import { DisbursementConstants } from '../constants';
import ESignedLoans from '../E-SignedLoans/index';

const DisbursementWrapper = () => {

  const location = useLocation();
  const { updateParams } = useQueryParams({ params: new LoanParams() });
  const [activeTab, setActiveTab] = useState("1");

  const tabs: TabsProps[] = [
    {
      label: DisbursementConstants.E_SIGNED_LOANS_LABEL,
      key: "1",
      component: <ApprovedLoans />,
      hash: DisbursementConstants.E_SIGNED_LOANS_HASH,
    },
    {
      label: DisbursementConstants.DISBURSEMENT_LOANS_LABEL,
      key: "2",
      component: <DisbursementInProgress />,
      hash: DisbursementConstants.DISBURSEMENT_LOANS_HASH,
    },
    {
      label: DisbursementConstants.APPROVED_LOANS_LABEL,
      key: "3",
      component: <ESignedLoans />,
      hash: DisbursementConstants.APPROVED_LOANS_HASH
    }
  ];

  useEffect(() => {

    setActiveTab(
      String(
        tabs?.find((tab) => location?.hash === tab?.hash)?.key ?? activeTab
      )
    );

  }, [activeTab]);

  useEffect(() => {
    if (!location.hash) {
      window.location.hash = tabs?.find((tab) => tab.key === activeTab)?.hash ?? '';
    }
  }, [location.hash])

  const handleTabChange = (tabNumber: string) => {
    const hash = tabs?.find((tab, index) => tab?.key === tabNumber)?.hash;
    updateParams({ page: 1 }, hash);
    setActiveTab(tabNumber);
  }

  const tabDetails = tabs?.find((tab) => location.hash === tab?.hash)
  const tabKey = String(tabDetails?.key)

  return (
    <div className='disbursement-wrapper'>
      <Tabs
        defaultActiveKey="1"
        activeKey={tabKey !== undefined ? tabKey : "1"}
        onChange={handleTabChange}
      >
        {tabs?.map(({ label, key, component }) => (
          <Tabs.TabPane tab={label} key={key}>
            {component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  )
}

export default DisbursementWrapper