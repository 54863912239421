export enum TableConfig {
  CUSTOMERS = "customers",
  USERS = "users",
  REPAYMENTS = "repayments",
  BRANCHES = "branches",
  CENTER = "center",
  LOAN_TYPES = "loanTypes",
  DISBURSEMENT = "disbursement",
  LOANS = "loans",
  PIPELINE = "pipeline",
  CT_VERIFICATION = "ctVerification",
  ACCOUNTANT = "accountant",
  BRANCH_ACCOUNTANT = "branchAccountant",
  LOAN_CLAIMS = "loanClaims",
  MOD_OFFICER = "modOfficer",
  APPROVED_LOANS = "approvedLoans",
  DISBURSEMENT_LOANS = "disbursementLoans",
  E_SIGNED_LOANS = "eSignedLoans",
}
