export const CommentsConstants = {
    IMAGE_ALT: "image",
    ALL_COMMENTS: "All Comments",
    ADD_COMMENTS: "ADD COMMENTS",
    TIME_LINE_COLOR: "#105f49",
    TIMELINE_DATE_FORMAT: "MMMM Do, YYYY",
    SUBMITTED_BY: "Submitted By",
    ID: "ID:",
    BRANCH: "Branch",
    DATE: "Date",
    CREATED_AT_DATE_FORMAT: "DD-MM-YYYY hh:mm a",
    NO_COMMENTS_AVAILABLE: "No comments available",
    AVATAR_ALT: "avatar-alt",
    CREATED_AT: "created_at",
    WIDTH: "750px",
    HEIGHT: "500px",
    IFRAME_TITLE: "document",
    ADD_COMMENT: "Add Comment",
    DRAWER_WIDTH: 600
}