import React, { useEffect, useState } from "react";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import {
  Loan,
  Loan as LoanModel,
  LoanParams,
} from "../../../models/loan.model";
import { generatePath, useNavigate } from "react-router-dom";
import useSorting from "../../../shared/hooks/useSorting";
import { LocalStorage } from "../../../enums/localStorage.enum";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import useTableConfig from "../../../shared/hooks/useTableConfig";
import { DateRange } from "../../../shared/types/date.type";
import { Filters } from "../../../models/filters.model";
import { LoanService } from "../../../services/Loan/loan.service";
import { LoanStatus } from "../../../enums/loanStatus.type";
import Table from "../../../shared/components/Table";
import { Button, Checkbox, Col, Drawer, Modal, Row, Select, TableColumnsType, TableProps } from "antd";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import { ColumnsType, SorterResult } from "antd/lib/table/interface";
import FilterButtons from "../../../shared/components/FilterButtons";
import ColumnOptions from "../../../shared/components/ColumnOptions";
import useDrawer from "../../../shared/hooks/useDrawer";
import LoanFilters from "../IndividualDisburesement/DisburesementFilters";
import CustomRangePicker from "../../../shared/components/CustomRangePicker";
import TabExtraContent from "../../../shared/components/TabExtraContent";
import "./approvedLoans.scss";
import ChangeStatus from "../IndividualDisburesement/ChangeStatus";
import DisburseAmountForm from "../IndividualDisburesement/DisburseAmountForm";
import { DisbursementModel } from "../../../models/disbursement.model";
import CustomModal from "../../../shared/components/CustomModal";
import { modLoansTypes } from "../../../enums/modLoans.type";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";
import { MODStatus } from "../../../enums/modStatusTags.type";
import { getFullName } from "../../../shared/utils/getFullName";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import MODUploadForm from "../../MODDocuments/MODUploadForm";
import { updateSelectedValue } from "../../../shared/utils/modFilter";
import changePreferredChannelOptions from "../../../shared/Constant/changePreferredChannelOptions";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { renderModStatus } from "../../../shared/utils/renderModStatus";
import { Process } from "../../../enums/process.enum";
import { getColumnTitles, getDefaultColumns, getColumnKeys } from '../../../shared/utils/columnUtil';
import { ColumnsService } from "../../../services/Columns/columns.service";
import { ColumnOrder } from "../../../enums/columnOrder.enum";
import { blockedVclTags, DisbursementConstants } from "../constants";
import { Buttons } from "../../../enums/buttons.enum";

const sortBy = {
  code: "code",
  centerName: "center_name",
  name: "customer_name",
  createdByFirstName: "created_by.firstname",
  loanTypeName: "loan_type_name",
  status: "status",
  modStatus: "mod_status",
  organisations: "organisations",
  organizationName: "organisation_name",
  currentStatusTag: "current_status_tag"
};

const ApprovedLoans = () => {
  const {
    params: approvedParams,
    updateParams,
    getParams,
    handleSearch,
  } = useQueryParams({
    params: new LoanParams(),
  });

  const columns: TableColumnsType<Loan> = [
    {
      key: 1,
      title: "Loan ID",
      dataIndex: "code",
      render: (code: string) => code?.toUpperCase(),
      sorter: true
    },
    {
      key: 2,
      title: "Partner ID",
      dataIndex: "laf",
      render: (laf: string) => fillEmptyData(laf?.toUpperCase()),
    },
    {
      key: 3,
      title: "Customer Name",
      dataIndex: "name",
      render: (_: string, record: Loan) => {
        return record?.customer?.getName() || '-';
      },
      sorter: true
    },
    {
      key: 4,
      title: "BC Status Tag",
      dataIndex: "currentStatusTag",
      render: (_: string, record: Loan) => {
        if (!record?.currentStatusTag) return " - "
        const className =
          "active-status--" + record.currentStatusTag?.split("_")?.join("-");
        return (
          <span
            className={`text-success text-capitalize active-status ${className}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {removeUnderscore(record?.getBCTagLabel() ?? "")}
          </span>
        );
      },
    },
    {
      key: 5,
      title: "Security",
      dataIndex: "securedType",
      render: (_: string, record: Loan) => renderModStatus(record?.securedType),
    },
    {
      key: 6,
      title: "MOD Status",
      dataIndex: "modStatus",
      render: (_: string, record: Loan) => {
        if (!record?.modStatus) return " - "
        const className =
          "active-status--" + record.modStatus?.split("_")?.join("-");
        const isUpload = record.modStatus === MODStatus.UPLOAD_MOD || (record.modStatus === MODStatus.PENDING_ACKNOWLEDGEMENT && !record.modDocumentUrl)
        const isEmLoan = record.securedType === modLoansTypes.EQUAITABLE_MORTAGE
        return (
          <span
            className={`text-success text-capitalize active-status ${className} ${((isUpload && !isEmLoan) && "text-underline")}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (isUpload && !isEmLoan) {
                setUploadModal(true)
                setModLoan(record)
              }
            }}
          >
            {removeUnderscore(record.getModStatusLabel() ?? "")}
          </span>
        );
      },
    },
    {
      key: 7,
      title: "MOD Document ID",
      dataIndex: "modDocumentId",
      render: (_: string, record: Loan) => record?.modDocumentId
    },
    {
      key: 8,
      title: "Center",
      dataIndex: "centerName",
      sorter: true,
    },

    {
      key: 9,
      title: "CRO",
      dataIndex: "createdByFirstName",
      sorter: true,
      render: (_: string, record: Loan) => getFullName(record?.createdBy),
    },
    {
      key: 10,
      title: "Loan Type",
      dataIndex: "loanTypeName",
      sorter: true,
    },
    {
      key: 11,
      title: "Batch Id",
      dataIndex: "batchId",
    },
    {
      key: 12,
      title: "Branch Name",
      dataIndex: "branch",

      render: (_: string, record: Loan) => record?.branch?.name,
    },

    {
      key: 13,
      title: "Total Amount",
      dataIndex: "requestedLoanAmt",
    },
    {
      key: 14,
      title: "Status",
      dataIndex: "status",
      sorter: true,
      render: (_: string, record: Loan) => {
        const className = "active-status--" + record.status?.split("_")?.join("-");
        return (
          <span
            className={`text-success text-capitalize active-status ${className}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {removeUnderscore(record.getLoanStatusLabel() ?? "")}
          </span>
        );
      },
    },
    {
      key: 15,
      title: "Preferred channel",
      dataIndex: "organizationName",
      sorter: true
    },
  ];

  const navigate = useNavigate();
  const { columnConfig, getColumnOrder, setColumnConfig } = useTableConfig();
  const storedColumnOrder = getColumnOrder(ColumnOrder.APPROVED_LOANS);
  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.approvedLoans ?? []
  );
  const userAccess = LocalStorageHelper.getItem(
    LocalStorage.CURRENT_USER_ACCESS
  );
  const [filters, setFilters] = useState<Filters>(new Filters());
  const { updateSortData } = useSorting<Loan>({ sortBy });
  const [loans, setLoans] = useState<Loan[]>([]);
  const [loanTotalPage, setLoanTotalPage] = useState(1);
  const [addedAmount, setAddedAmount] = useState<number>(0);
  const [selectedLoans, setSelectedLoans] = useState<number[]>([]);
  const [selectedRows, setSelectedRows] = useState<Loan[]>([])
  const [, setDateRange] = useState<DateRange>();
  const [disburseAmount, setDisburseAmount] = useState<number>(0);
  const [preferredChannelModal, setPreferredChannelModal] = useState(false)
  const [modLoan, setModLoan] = useState<Loan>(new Loan())
  const [uploadModModal, setUploadModal] = useState(false)
  const [selectedValue, setSelectedValue] = useState(approvedParams?.securityType);
  const storedColumnKeys = getColumnKeys(storedColumnOrder)
  const employee = LocalStorageHelper.getItem(LocalStorage.EMPLOYEE);
  const [originalOrder, setOriginalOrder] = useState<string[]>([])
  const [defaultColumns, setDefaultColumns] = useState(
    getDefaultColumns(columns, storedColumnOrder, storedColumnKeys, originalOrder)
  );


  useEffect(() => {
    fetchColumnOrder()
  }, []);

  useEffect(() => {
    if (originalOrder && originalOrder.length) {
      setColumns();
      setDeletedColumns()
    }
  }, [originalOrder]);

  const tableColumnTitles = getColumnTitles(columns)

  const { getLoans, loading, generateLoanReport, initiateDisburseStatus, changePreferredChannel } =
    LoanService();

  const { createColumns, getColumns, updateColumns } = ColumnsService()

  const publishColumns = async () => {
    await createColumns({
      pageName: ColumnOrder.APPROVED_LOANS,
      columnArray: tableColumnTitles,
      employeeId: employee?.id
    })
  }

  useEffect(() => {
    const hasDifference = columns.some((col, index) => {
      return typeof col.title === 'string' && col.title?.trim() !== defaultColumns[index]?.title;
    });

    if (hasDifference) {
      publishColumns();
    }
  }, []);

  const fetchColumnOrder = async () => {
    const columnOrder = await getColumns({
      pageName: ColumnOrder.APPROVED_LOANS,
      employeeId: employee.id
    })
    setOriginalOrder(columnOrder?.columnArray ?? [])
    setColumnConfig(ColumnOrder.APPROVED_LOANS, columnOrder?.columnArray ?? [])
  }

  const setColumns = () => {
    if (originalOrder && originalOrder.length > 0) {
      const updatedColumns = getDefaultColumns(columns, storedColumnOrder, storedColumnKeys, originalOrder);
      setDefaultColumns(updatedColumns);
    } else {
      setDefaultColumns(columns);
    }
  }

  const updateColumn = async (columns: ColumnsType<Loan>) => {
    await updateColumns({
      columnArray: getColumnTitles(columns),
      pageName: ColumnOrder.APPROVED_LOANS
    })
  }

  const setDeletedColumns = () => {
    const deletedColumns = columns.filter((col) =>
      typeof col.title === 'string' &&
      !originalOrder?.includes(col.title.trim())
    );
    const deletedColumnTitles = deletedColumns.map((col) => col.title as string);
    setFilteredColumns(deletedColumnTitles)
  }

  const fetchLoans = async () => {
    const updatedParams = {
      ...approvedParams,
      approved: true,
      searchText: approvedParams?.searchText,
    };

    const loanDetails = await getLoans(updatedParams);
    const val = loanDetails?.loans?.filter(
      (loan) => loan?.status === LoanStatus.E_SIGN_SUCCESS
    );
    if (val) setLoans(val);
    if (loanDetails?.meta) {
      loanDetails?.meta?.filters && setFilters(loanDetails?.meta?.filters);
      setLoanTotalPage(loanDetails?.meta?.totalCount ?? 1);
    }
  };

  useEffect(() => {
    fetchLoans();
  }, [approvedParams, approvedParams.searchText]);

  const handleSelectedLoans = (loans: number[]) => setSelectedLoans(loans);
  const handleSelectedRows = (loans: Loan[]) => setSelectedRows(loans);

  const changePreferredChannelCall = async () => {
    await changePreferredChannel({ loanIds: selectedLoans });
    setPreferredChannelModal(false);
    handleSelectedLoans([]);
    fetchLoans();
  }

  const {
    visible: approvedSettingsVisible,
    toggleVisibility: toggleApprovedSettingsVisible,
  } = useDrawer({});

  const {
    visible: approvedFiltersVisible,
    toggleVisibility: toggleApprovedFiltersVisible,
  } = useDrawer({});

  const {
    visible: isDisburseDrawerVisible,
    toggleVisibility: toggleDisburseDrawer,
  } = useDrawer({});
  const {
    visible: isChangeStatusVisible,
    toggleVisibility: toggleChangeStatusVisible,
  } = useDrawer({});

  const { visible: disbursementConfirmationModal, toggleVisibility: toggleDisbursementConfirmationModal } = useDrawer({})

  const handleAmount = (loan: Loan[]) => {
    setAddedAmount(
      loan.reduce(
        (sum, { requestedLoanAmt }) => sum + Number(requestedLoanAmt),
        0
      )
    );
  };

  const modFilterChange = (value: string) => {
    updateSelectedValue(value, setSelectedValue, updateParams);
  };

  const rowSelection = {
    selectedRowKeys: selectedLoans,
    onChange: (selectedRowKeys: React.Key[], loans: Loan[]) => {
      const paymentIntentIds = loans.map((loans) => loans?.id ?? 0);
      handleSelectedLoans(paymentIntentIds);
      handleSelectedRows(loans)
      handleAmount(loans);
    },
  };

  const handleChangeModal = (change: string) => {
    const notApplicableLoansPresent = selectedRows.some((row) => row.currentStatusTag && blockedVclTags.includes(row.currentStatusTag));
    if (notApplicableLoansPresent) {
      Notification({
        message: `These Loans are not applicable for the changing ${change}`,
        description: "",
        type: NotificationTypes.ERROR
      })
    } else {
      if (change === 'Status') {
        toggleChangeStatusVisible(true);
      } else if (change === 'Preferred Channel') {
        setPreferredChannelModal(true);
      }
    }
  }

  const handleUpdateParams = () => updateParams(getParams());

  const handleRowChange = (record: Loan) => ({
    onClick: () =>
      record?.id &&
      navigate(
        generatePath(AppRoutes.DISBURSEMENT_DETAILS, {
          loanId: String(record?.id),
        })
      ),
  });

  const handleChange: TableProps<LoanModel>["onChange"] = (
    { current },
    _,
    sorter
  ) => {
    const { sortBy, sortDirection } = updateSortData(
      sorter as SorterResult<Loan>
    );
    updateParams({ ...approvedParams, page: current, sortBy, sortDirection });
  };

  const handleDisbursementStatus = () => {
    if (selectedLoans.length) {
      toggleDisbursementConfirmationModal();
    } else {
      toggleDisburseDrawer(true);
    }
  }

  const handleDisbursement = async () => {
    if (!selectedLoans.length) toggleDisburseDrawer(true);
    
    const params = {
      loanIds: selectedLoans,
      status: LoanStatus.DISBURSEMENT_IN_PROGRESS,
    };

    const result = await initiateDisburseStatus(params);
    if (!result) return;
    await generateLoanReport({
      loanIds: selectedLoans,
      templateName: DisbursementConstants.LOAN_REPORT_TEMPLATE_NAME,
      batchCode: result?.batchCode
    });
    setSelectedLoans([]);
    fetchLoans();
    toggleDisbursementConfirmationModal();
  };

  const changeStatusClose = () => {
    toggleChangeStatusVisible(false);
    handleSelectedLoans([]);
    fetchLoans()
  }

  const toggleSelectAll = (loanValues: Loan[]) => {
    setSelectedLoans((keys) =>
      keys.length === loanValues.length
        ? []
        : loanValues.map((r) => Number(r.id))
    );
    setSelectedLoans(loanValues?.map((loan) => loan?.id).filter((id): id is number => id !== undefined));
    handleAmount(loanValues);
  };

  const handleDateChange = ({ approvedToDate, approvedFromDate }: DateRange) => {
    setDateRange({ approvedToDate: approvedToDate, approvedFromDate: approvedFromDate });
    updateParams({ ...approvedParams, approvedFromDate, approvedToDate });
  };

  return (
    <div className="approved-loans">
      <div className="approved-loans-picker">
        <Row align={"middle"}>
          <Col span={9}>
            <Checkbox
              checked={selectedValue === modLoansTypes.ALL}
              onChange={() => modFilterChange(modLoansTypes.ALL)}
            >
              All Loans
            </Checkbox>
            <Checkbox
              checked={approvedParams?.securityType === modLoansTypes.SECURED}
              onChange={() => modFilterChange(modLoansTypes.SECURED)}
            >
              Secured Loans
            </Checkbox>
            <Checkbox
              checked={approvedParams?.securityType === modLoansTypes.UNSECURED}
              onChange={() => modFilterChange(modLoansTypes.UNSECURED)}
            >
              Unsecured Loans
            </Checkbox>
          </Col>
          <Col span={6}>
            <CustomRangePicker
              approvedDate
              className="mr-5 custom-range-picker"
              onChange={handleDateChange}
              values={{
                toDate: approvedParams?.approvedToDate,
                fromDate: approvedParams?.approvedFromDate,
              }}
            />
          </Col>
          {selectedLoans.length > 0 && (
            <>
              <Col span={2}>
                <span className="loan__text">
                  Selected Loans ({selectedLoans.length})
                </span>
              </Col>

              <Col span={3} offset={1}>
                <span className="text-primary">
                  Added Amount :{" "}
                  <span className="text-bold mt-5"> {addedAmount}</span>{" "}
                </span>
              </Col>
              {
                <Col span={3}>
                  <span className="text-primary">
                    Remaining Amount :{" "}
                    <span className="loan__text">
                      {disburseAmount ? disburseAmount - addedAmount : "NA"}
                    </span>{" "}
                  </span>
                </Col>
              }
            </>
          )}
        </Row>
      </div>
      {!loading && filters && (
        <FilterButtons filters={filters} onFilter={handleUpdateParams} />
      )}
      <Table
        className='approved-loans-table'
        rowKey="id"
        scroll={{ x: true }}
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col?.title as string)
        )}
        {...(userAccess?.writeAccess && {
          rowSelection: {
            preserveSelectedRowKeys: true,
            type: "checkbox",
            ...rowSelection,
          },
        })}
        dataSource={loans}
        loading={loading}
        onRow={handleRowChange}
        showSorterTooltip={false}
        tableKey={ColumnOrder.APPROVED_LOANS}
        onChange={handleChange}
        pagination={{
          current: approvedParams?.page,
          total: loanTotalPage,
          hideOnSinglePage: true,
        }}
      />
      <div className="approved-extra-content">
        <div className="selected-loan-details">
          <Row gutter={[10, 0]}>
            {userAccess?.writeAccess && (
              <div className="d-flex">
                <Button
                  disabled={!selectedLoans.length}
                  className="loan-status-btn mr-2"
                  onClick={() => handleChangeModal('Status')}
                >
                  Change Status
                </Button>

                <Button
                  className="loan-disburse-btn ml-2 mr-2"
                  onClick={handleDisbursementStatus}
                >
                  {selectedLoans.length
                    ? DisbursementConstants.MOVE_LOANS_TO_DISBURSEMENT
                    : DisbursementConstants.SELECT_LOANS_TO_DISBURSE}
                </Button>
              </div>
            )}
          </Row>
        </div>
        <TabExtraContent
          searchProps={{
            onSearch: handleSearch,
            placeholder: "Search Loan",
            value: approvedParams.searchText,
          }}
          filterClick={toggleApprovedFiltersVisible}
          settingsClick={toggleApprovedSettingsVisible}
        />
      </div>
      <Drawer
        placement="right"
        visible={approvedSettingsVisible}
        destroyOnClose
        onClose={() => toggleApprovedSettingsVisible(false)}
        title="Column Options"
        width={500}
      >
        <ColumnOptions
          tableColumns={columns}
          defaultColumns={defaultColumns}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          onClose={() => toggleApprovedSettingsVisible(false)}
          setDefaultColumns={setDefaultColumns}
          tableKey={ColumnOrder.APPROVED_LOANS}
          onAction={updateColumn}
        />
      </Drawer>
      <Modal
        visible={isChangeStatusVisible}
        onCancel={() => toggleChangeStatusVisible(false)}
        cancelButtonProps={{ className: "modal-footer__cancel" }}
        destroyOnClose
        centered
        footer={false}
      >
        <ChangeStatus
          selectedLoans={selectedLoans}
          activeTab="1"
          onClose={changeStatusClose}
        />
      </Modal>
      <Drawer
        placement="right"
        visible={isDisburseDrawerVisible}
        destroyOnClose
        onClose={() => toggleDisburseDrawer(false)}
        title="Disburse Amount"
        width={"35vw"}
      >
        <DisburseAmountForm
          onSuccess={(loansValue: Loan[], formValues: DisbursementModel) => {
            setLoans(loansValue);
            setDisburseAmount(formValues?.disburseAmount ?? 0);
            formValues.process === Process.AUTOMATIC && toggleSelectAll(loansValue);
          }}
          toggleDisburseDrawer={toggleDisburseDrawer}
          setSelectedLoans={setSelectedLoans}
        />
      </Drawer>
      <Drawer
        placement="right"
        visible={approvedFiltersVisible}
        destroyOnClose
        onClose={() => toggleApprovedFiltersVisible(false)}
        title="Filters"
        width={"70vw"}
      >
        <LoanFilters
          onFilter={updateParams}
          onClose={() => toggleApprovedFiltersVisible(false)}
        />
      </Drawer>
      <CustomModal
        title="Change Preferred Channel"
        visible={preferredChannelModal}
        onCancel={() => setPreferredChannelModal(false)}
        onOk={changePreferredChannelCall}
      >
        <Select
          allowClear
          options={changePreferredChannelOptions}
          placeholder="Select a  Preferred Channel"
        >
        </Select>
      </CustomModal>
      <MODUploadForm refreshData={fetchLoans} loan={modLoan} visible={uploadModModal} onClose={() => setUploadModal(false)} securityDocument={true} />
      <CustomModal
        title={<h1>{DisbursementConstants.DISBURSEMENT_CONFIRMATION}</h1>}
        open={disbursementConfirmationModal}
        onCancel={toggleDisbursementConfirmationModal}
        onOk={handleDisbursement}
        onClose={toggleDisbursementConfirmationModal}
        className="disbursementConfirmationModal"
        okText={Buttons.CONFIRM}
      />
    </div>
  );
};

export default ApprovedLoans;
