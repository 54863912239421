export const VivitriBorrowerFields = [
    { label: 'Total Overdue Amount AOD', name: 'totalOverDueAmount' },
    { label: 'Max DPD Tradeline 3 Months', name: 'maxDpdTradeline3Months' },
    { label: 'Max DPD Tradeline 6 Months', name: 'maxDpdTradeline6Months' },
    { label: 'Max DPD Tradeline 12 Months', name: 'maxDpdTradeline12Months' },
    { label: 'Bureau Score', name: 'bureauScore' },
    { label: 'Amount Of Write Off, Suit Filed, Wilful Defaulter, Doubtful, Sub, Loss, Settlement, Restructuring In Credit Card/KCC/Gold Loan Last 36 Months', name: 'riskEvents' },
];
export const VivitriCoBorrowerFields = [
    { label: 'Total Overdue Amount AOD', name: 'cbTotalOverDueAmount' },
    { label: 'Max DPD Tradeline 3 Months', name: 'cbMaxDpdTradeline3Months' },
    { label: 'Max DPD Tradeline 6 Months', name: 'cbMaxDpdTradeline6Months' },
    { label: 'Max DPD Tradeline 12 Months', name: 'cbMaxDpdTradeline12Months' },
    { label: 'Bureau Score', name: 'cbBureauScore' },
    { label: 'Amount Of Write Off, Suit Filed, Wilful Defaulter, Doubtful, Sub, Loss, Settlement, Restructuring In Credit Card/KCC/Gold Loan Last 36 Months', name: 'cbRiskEvents' },
];
export enum vivtriSectionTitles {
    BORROWER = "Borrower",
    CO_BORROWER = "Co Borrower"
}

export const placeholder = "Enter"
