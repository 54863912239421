/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import TabExtraContent from "../../../shared/components/TabExtraContent";
import useQueryParams from "../../../shared/hooks/useQueryParams";
import { Loan, LoanParams } from "../../../models/loan.model";
import useDrawer from "../../../shared/hooks/useDrawer";
import { Filters } from "../../../models/filters.model";
import { LoanService } from "../../../services/Loan/loan.service";
import { Drawer, Table, TableColumnsType, TableProps } from "antd";
import useTableConfig from "../../../shared/hooks/useTableConfig";
import useSorting from "../../../shared/hooks/useSorting";
import { ColumnsType, SorterResult } from "antd/lib/table/interface";
import { generatePath, useNavigate } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import LoanFilters from "../../Loans/LoanFilters";
import ColumnOptions from "../../../shared/components/ColumnOptions";
import { TableConfig } from "../../../enums/tableConfig.type";
import { ColumnOrder } from "../../../enums/columnOrder.enum";
import { getColumnKeys, getColumnTitles, getDefaultColumns, getDeletedColumns, hasColumnDifference } from "../../../shared/utils/columnUtil";
import { LocalStorageHelper } from "../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../enums/localStorage.enum";
import { ColumnsService } from "../../../services/Columns/columns.service";
import { LoanStatus } from "../../../enums/loanStatus.type";
import { DisbursementConstants } from "../constants";
import { DrawerPlacement } from "../../../enums/drawerPlacement.enum";
import "./eSignedLoans.scss";
import { eSignedColumns } from "./eSignedColumns";
import { TableConfiguration } from "../../../shared/types/columnConfig.type";
import { FilterSection } from "./FilterSection";

const sortBy = {
  code: "code",
  name: "customer_name",
  centerName: "center_name",
  createdByFirstName: "created_by.firstname",
  loanTypeName: "loan_type_name",
  status: "status",
  organizationName: "organisation_name",
};

const ESignedLoans = () => {
  const { getLoans, loading } = LoanService();
  const handleUpdateParams = () => updateParams(getParams());
  const {
    params: eSignedParams,
    handleSearch,
    updateParams,
    getParams,
  } = useQueryParams<LoanParams>({
    params: new LoanParams(),
  });
  const [filters, setFilters] = useState<Filters>(new Filters());
  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<Loan>>(eSignedColumns);
  const [loans, setLoans] = useState<Loan[]>([]);
  const { columnConfig, setColumnConfig, getColumnOrder } = useTableConfig();
  const [totalLoans, setTotalLoans] = useState(1);
  const [filteredColumns, setFilteredColumns] = useState<string[]>(
    columnConfig?.eSignedLoans ?? []
  );
  const [config, setConfig] = useState<TableConfiguration>({
    columnConfig: null,
    originalOrder: [],
  });

  useEffect(() => {
    fetchLoans();
    fetchColumnOrder()
    if (hasColumnDifference(eSignedColumns, defaultColumns)) {
      publishColumns();
    }
  }, [eSignedParams, eSignedParams.searchText]);

  useEffect(() => {
    setColumnConfig(TableConfig.E_SIGNED_LOANS, filteredColumns);
  }, [filteredColumns]);

  useEffect(() => {
    if (config.originalOrder && config.originalOrder.length) {
      setColumns();
      setFilteredColumns(getColumnTitles(getDeletedColumns(eSignedColumns, config.originalOrder)))
    }
  }, [config.originalOrder]);

  const { updateSortData } = useSorting<Loan>({ sortBy });
  const navigate = useNavigate();

  const {
    visible: eSignedFilterVisible,
    toggleVisibility: toggleESignedFilterVisibility,
  } = useDrawer({});

  const {
    visible: eSignedSettingsVisibility,
    toggleVisibility: toggleEsignedSettingsVisibility,
  } = useDrawer({});

  const handleChange: TableProps<Loan>["onChange"] = (
    pagination,
    _,
    sorter
  ) => {
    const sortData = updateSortData(sorter as SorterResult<Loan>);
    updateParams({
      ...eSignedParams,
      ...sortData,
      page: pagination?.current,
    });
  };

  const fetchLoans = async () => {
    const updatedParams = {
      ...eSignedParams,
      searchText: eSignedParams.searchText,
      status: [LoanStatus.E_SIGN_SUCCESS],
    };
    const loanData = await getLoans(updatedParams);
    if (loanData?.loans) setLoans(loanData.loans);
    if (loanData?.meta) {
      setTotalLoans(loanData.meta.totalCount ?? 1);
      const updatedFilters = { ...loanData.meta.filters };
      delete updatedFilters.statuses;
      loanData.meta.filters && setFilters(updatedFilters);
    }
  };

  const setColumns = () => {
    if (config.originalOrder && config.originalOrder.length > 0) {
      const updatedColumns = getDefaultColumns(eSignedColumns, storedColumnOrder, storedColumnKeys, config.originalOrder);
      setDefaultColumns(updatedColumns);
    } else {
      setDefaultColumns(eSignedColumns);
    }
  }

  const fetchColumnOrder = async () => {
    const columnOrder = await getColumns({
      pageName: ColumnOrder.E_SIGNED_LOANS,
      employeeId: employee.id
    })
    !columnOrder && publishColumns()
    setConfig((prev) => ({
      ...prev,
      originalOrder: columnOrder?.columnArray ?? [],
      columnConfig: {
        ...prev.columnConfig,
        [ColumnOrder.E_SIGNED_LOANS]: columnOrder?.columnArray ?? [],
      },
    }));
  }

  const tableColumnTitles = getColumnTitles(eSignedColumns)
  const storedColumnOrder = getColumnOrder(ColumnOrder.E_SIGNED_LOANS);
  const storedColumnKeys = getColumnKeys(storedColumnOrder)
  const employee = LocalStorageHelper.getItem(LocalStorage.EMPLOYEE);
  const { createColumns, getColumns, updateColumns } = ColumnsService()

  const publishColumns = async () => {
    await createColumns({
      pageName: ColumnOrder.E_SIGNED_LOANS,
      columnArray: tableColumnTitles,
      employeeId: employee?.id
    })
  }

  const updateColumn = async (columns: ColumnsType<Loan>) => {
    await updateColumns({
      columnArray: getColumnTitles(columns),
      pageName: ColumnOrder.E_SIGNED_LOANS
    })
  }

  return (
    <div className="e-signed-loans">
      <FilterSection
        loading={loading}
        filters={filters}
        handleUpdateParams={handleUpdateParams}
      />
      <div className="e-signed-extra-content">
        <TabExtraContent
          searchProps={{
            onSearch: handleSearch,
            placeholder: DisbursementConstants.SEARCH_LOAN_PLACEHOLDER,
            value: eSignedParams.searchText,
          }}
          filterClick={toggleESignedFilterVisibility}
          settingsClick={toggleEsignedSettingsVisibility}
        />
      </div>
      <Table
        scroll={{ x: true }}
        className="e-signed-loans-table"
        columns={defaultColumns.filter(
          (col) => !filteredColumns.includes(col.title as string)
        )}
        dataSource={loans}
        loading={loading}
        onChange={handleChange}
        rowKey={(record: any) => record?.id}
        onRow={(record) => ({
          onClick: () => {
            navigate(
              generatePath(AppRoutes.DISBURSEMENT_DETAILS, {
                loanId: record?.id,
              }),
            );
          },
        })}
        pagination={{
          current: eSignedParams?.page,
          hideOnSinglePage: true,
          total: totalLoans,
        }}
      />
      {eSignedFilterVisible && (
        <Drawer
          placement={DrawerPlacement.RIGHT}
          onClose={() => toggleESignedFilterVisibility(false)}
          visible={eSignedFilterVisible}
          width={DisbursementConstants.FILTER_DRAWER_WIDTH}
          title={DisbursementConstants.FILTERS_LABEL}
          closable
          destroyOnClose
        >
          <LoanFilters
            onClose={() => toggleESignedFilterVisibility(false)}
            onFilter={updateParams}
          />
        </Drawer>
      )}

      {eSignedSettingsVisibility && (
        <Drawer
          placement={DrawerPlacement.RIGHT}
          onClose={() => toggleEsignedSettingsVisibility(false)}
          visible={eSignedSettingsVisibility}
          width={DisbursementConstants.COLUMN_DRAWER_WIDTH}
          title={DisbursementConstants.COLUMN_OPTIONS_LABEL}
          destroyOnClose
        >
          <ColumnOptions
            defaultColumns={defaultColumns}
            filteredColumns={filteredColumns}
            setFilteredColumns={setFilteredColumns}
            onClose={() => toggleEsignedSettingsVisibility()}
            setDefaultColumns={setDefaultColumns}
            tableColumns={eSignedColumns}
            onAction={updateColumn}
            tableKey={ColumnOrder.E_SIGNED_LOANS}
          />
        </Drawer>
      )}
    </div>
  );
};

export default ESignedLoans;
