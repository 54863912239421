import { BCStatusTags } from "../../enums/currentStatusTags.type";
import { NotificationTypes } from "../../enums/notificationTypes";
import { organisationNames } from "../../enums/organisationNames";
import Notification from "../components/Notification";

export const validateAndNotify = (
    organizationName: string,
    shouldMatchOrganisationName: organisationNames,
    statusTag: BCStatusTags | undefined,
    blockedTags: BCStatusTags[],
    notificationMessage: string
): boolean => {
    if (
        organizationName === shouldMatchOrganisationName &&
        statusTag &&
        blockedTags.includes(statusTag)
    ) {
        Notification({
            message: notificationMessage,
            description: "",
            type: NotificationTypes.INFO,
        });
        return false;
    }
    return true;
};
