export const UploadLoanAgreementConstants = {
    TITLE: "Upload Loan Agreement",
    UPLOAD_DOCUMENTS: "Upload Loan Documents",
    ACCEPT: "image/*,application/pdf",
    SUCCESS_MESSAGE: "All Documents have been Uploaded Successfully",
    ERROR_MESSAGE: "Error occured while uploading the documents",
    LOAN_AGREEMENT: {
        FIELD: "loanAgreementDocId",
        PLACEHOLDER: "Upload Loan Agreement",
        LABEL: "Loan Agreement",
        VALIDATION: "Loan Agreement is required!",
    },
    PDC: {
        FIELD: "pdcDocId",
        PLACEHOLDER: "Upload PDC Document",
        LABEL: "PDC Document",
        VALIDATION: "PDC Document is required!"
    },
    NACH: {
        FIELD: "nachDocId",
        PLACEHOLDER: "Upload NACH Document",
        LABEL: "NACH Document",
        VALIDATION: "NACH Document is required!"
    }
}