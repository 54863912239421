import { Form, Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { LoanAgreement } from "../../../../models/loanAgreement.model";
import { ModService } from "../../../../services/MODOfficer/modofficer.service";
import { DocumentUpload } from "../../../../models/fileAttachment.model";
import { DocumentType } from "../../../../enums/documentType.type";
import CustomModal from "../../../../shared/components/CustomModal";
import AttachmentUpload from "../../../../shared/components/AttachmentUpload";
import { Button, Col } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { UploadLoanAgreementConstants } from "./constants";
import { Loan } from "../../../../models/loan.model";
import { Buttons } from "../../../../enums/buttons.enum";
import { ButtonType, HTMLButtonType } from "../../../../enums/buttonType";
import { UploadLoanAgreementValidation } from "./validation";
import "../loanIndividualDetails.scss";
import { AttachmentUploadConstants } from "../../../../shared/components/AttachmentUpload/constants";
import Notification from "../../../../shared/components/Notification";
import { NotificationTypes } from "../../../../enums/notificationTypes";
import { LoanService } from "../../../../services/Loan/loan.service";

interface UploadLoanAgreementProps {
  visible: boolean;
  toggleVisibility: () => void;
  loan: Loan;
  refetch: () => void;
  isVclLoan?: boolean;
}

const UploadLoanAgreement = (props: UploadLoanAgreementProps) => {
  const { loan, visible, toggleVisibility, refetch, isVclLoan = false } = props;

  const { createDocument } = ModService();
  const { vclLoanAgreementCheck } = LoanService()

  const handleSubmit = async (
    values: DocumentUpload,
    { resetForm }: FormikHelpers<DocumentUpload>
  ) => {
    const documentsToUpload = [
      {
        frontSideImageId: values.loanAgreementDocId,
        documentType: DocumentType.LOAN_AGREEMENT,
      },
      { frontSideImageId: values.pdcDocId, documentType: DocumentType.PDC },
      { frontSideImageId: values.nachDocId, documentType: DocumentType.NACH },
    ];

    const validDocuments = documentsToUpload.filter(doc => doc.frontSideImageId);

    try {
      await Promise.all(
        validDocuments.map(document =>
          createDocument(
            {
              frontSideImageId: document.frontSideImageId!,
              documentType: document.documentType,
            },
            Number(loan.id)
          )
        )
      );
      await vclLoanAgreementCheck(Number(loan.id))
      Notification({
        message: UploadLoanAgreementConstants.SUCCESS_MESSAGE,
        description: "",
        type: NotificationTypes.SUCCESS,
      });
      resetForm();
      toggleVisibility();
      refetch();
    } catch (err) { 
      Notification({
        message: UploadLoanAgreementConstants.ERROR_MESSAGE,
        description: (err as Error)?.message,
        type: NotificationTypes.ERROR,
      });
    }
  }

  const [loading, setLoading] = useState(false);

  return (
    <div>
      <Formik
        validationSchema={UploadLoanAgreementValidation(isVclLoan)}
        initialValues={new LoanAgreement()}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, isValid, dirty, values }) => {
          return (
            <CustomModal
              className="branchAccountantUpload"
              title={
                isVclLoan
                  ? UploadLoanAgreementConstants.UPLOAD_DOCUMENTS
                  : UploadLoanAgreementConstants.TITLE
              }
              visible={visible}
              onCancel={toggleVisibility}
              isOkButton={false}
              isCancelButton={false}
              okText={Buttons.CONFIRM}
              closable={false}
            >
              <Form className="ant-form-vertical">
                <Col span={24}>
                  <Col className="ant-form-item-label">
                    {UploadLoanAgreementConstants.LOAN_AGREEMENT.LABEL}
                  </Col>
                  <AttachmentUpload
                    accept={UploadLoanAgreementConstants.ACCEPT}
                    name={UploadLoanAgreementConstants.LOAN_AGREEMENT.FIELD}
                    placeholder={
                      UploadLoanAgreementConstants.LOAN_AGREEMENT.PLACEHOLDER
                    }
                    setFieldValue={setFieldValue}
                    isLoading={(value) => setLoading(value)}
                  />
                  {values.loanAgreementDocId && !loading && (
                    <Col className="mt-5" span={8}>
                      <span className="text-primary mr-5">
                        {AttachmentUploadConstants.FILE_UPLOADED}
                      </span>
                      <CloseCircleOutlined
                        onClick={() =>
                          setFieldValue(
                            UploadLoanAgreementConstants.LOAN_AGREEMENT.FIELD,
                            undefined
                          )
                        }
                      />
                    </Col>
                  )}

                  {isVclLoan && (
                    <div className="mt-5">
                      <Col className="ant-form-item-label">
                        {UploadLoanAgreementConstants.PDC.LABEL}
                      </Col>
                      <AttachmentUpload
                        accept={UploadLoanAgreementConstants.ACCEPT}
                        name={UploadLoanAgreementConstants.PDC.FIELD}
                        placeholder={
                          UploadLoanAgreementConstants.PDC.PLACEHOLDER
                        }
                        setFieldValue={setFieldValue}
                        isLoading={(value) => setLoading(value)}
                      />
                      {values.pdcDocId && !loading && (
                        <Col className="mt-5" span={8}>
                          <span className="text-primary mr-5">
                            {AttachmentUploadConstants.FILE_UPLOADED}
                          </span>
                          <CloseCircleOutlined
                            onClick={() =>
                              setFieldValue(
                                UploadLoanAgreementConstants.PDC.FIELD,
                                undefined
                              )
                            }
                          />
                        </Col>
                      )}
                    </div>
                  )}
                  {isVclLoan && (
                    <div className="mt-5">
                      <Col className="ant-form-item-label">
                        {UploadLoanAgreementConstants.NACH.LABEL}
                      </Col>
                      <AttachmentUpload
                        accept={UploadLoanAgreementConstants.ACCEPT}
                        name={UploadLoanAgreementConstants.NACH.FIELD}
                        placeholder={
                          UploadLoanAgreementConstants.NACH.PLACEHOLDER
                        }
                        setFieldValue={setFieldValue}
                        isLoading={(value) => setLoading(value)}
                      />
                      {values.nachDocId && !loading && (
                        <Col className="mt-5" span={8}>
                          <span className="text-primary mr-5">
                            {AttachmentUploadConstants.FILE_UPLOADED}
                          </span>
                          <CloseCircleOutlined
                            onClick={() =>
                              setFieldValue(
                                UploadLoanAgreementConstants.NACH.FIELD,
                                undefined
                              )
                            }
                          />
                        </Col>
                      )}
                    </div>
                  )}
                  <Col span={24} className="mt-5">
                    <div className="drawer-footer text-right upload-modal-footer">
                      <Button
                        className="drawer-footer__cancel mr-2"
                        htmlType={HTMLButtonType.RESET}
                        onClick={toggleVisibility}
                      >
                        {Buttons.CANCEL}
                      </Button>
                      <Button
                        htmlType={HTMLButtonType.SUBMIT}
                        disabled={!dirty || !isValid || loading}
                        loading={loading}
                        type={ButtonType.DEFAULT}
                        className="ml-5 mod-upload-button drawer-footer__submit"
                      >
                        {Buttons.UPLOAD}
                      </Button>
                    </div>
                  </Col>
                </Col>
              </Form>
            </CustomModal>
          );
        }}
      </Formik>
    </div>
  );
};

export default UploadLoanAgreement;
