import { ColumnsType } from "antd/lib/table";
import { LocalStorage } from "../../enums/localStorage.enum";
import { ColumnConfigProps } from "../types/columnConfiguration.type";
import { LocalStorageHelper } from "../utils/localStorageHelper";
import { TableColumnsType } from "antd";

export interface ExtendedColumnConfigProps extends ColumnConfigProps {
  columnOrder?: { [key: string]: TableColumnsType };
  visibleColumns?: string[];
}

const useTableConfig = <T extends unknown>() => {
  const columnConfig = LocalStorageHelper.getItem(
    LocalStorage.COLUMN_CONFIG
  )

  const setColumnConfig = (
    columnKey: string,
    updatedValue: string[],
    columnOrder?: ColumnsType<T>
  ) => {
    const updatedConfig: ExtendedColumnConfigProps = {
      ...columnConfig,
      [columnKey]: updatedValue,
    };

    if (columnOrder) {
      updatedConfig.columnOrder = {
        ...columnConfig?.columnOrder,
        [columnKey]: columnOrder
      };
    }

    LocalStorageHelper.setItem(LocalStorage.COLUMN_CONFIG, updatedConfig);
  };

  const getColumnOrder = (columnKey: string) => {
    return columnConfig?.columnOrder?.[columnKey];
  };

  return {
    columnConfig,
    setColumnConfig,
    getColumnOrder
  };
};

export default useTableConfig;