import * as Yup from "yup"
import { UploadLoanAgreementConstants } from "./constants"
export const UploadLoanAgreementValidation = (isVclLoan: boolean) =>
    Yup.object().shape({
        loanAgreementDocId: Yup.string().required(UploadLoanAgreementConstants.LOAN_AGREEMENT.VALIDATION),
        pdcDocId: Yup.string().when([], {
            is: () => isVclLoan,
            then: Yup.string().required(UploadLoanAgreementConstants.PDC.VALIDATION),
            otherwise: Yup.string(),
        }),
        nachDocId: Yup.string().when([], {
            is: () => isVclLoan,
            then: Yup.string().required(UploadLoanAgreementConstants.NACH.VALIDATION),
            otherwise: Yup.string(),
        }),
    })