import React from "react";
import FilterButtons from "../../../../shared/components/FilterButtons";
import { Filters } from "../../../../models/filters.model";

interface FilterSectionProps {
    loading: boolean
    filters: Filters
    handleUpdateParams: () => void
}

export const FilterSection = (props: FilterSectionProps) => {
    const { loading, filters, handleUpdateParams } = props;
    return (
        <>
            {!loading && (
                <FilterButtons filters={filters} onFilter={handleUpdateParams} />
            )}
        </>
    );
}
