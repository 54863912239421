import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import * as appRoutes from "./routeConstants/appRoutes";
import isAuthenticated from "../shared/components/HOC/requireAuth";

import { RouterProps } from "../shared/types/route.type";
import AppComponents from "../views/AppComponents";
import Login from "../views/Auth/Login";
import * as AppRoutes from "../routes/routeConstants/appRoutes";
import Profile from "../views/Profile";
import Dashboard from "../views/Dashboard";
import Users from "../views/Users";
import UserDetails from "../views/Users/UserDetails";
import Customers from "../views/Customers";
import CustomerDetail from "../views/Customers/CustomerDetail";
import Loans from "../views/Loans";
import Configuration from "../views/Configurations";
import BranchDetails from "../views/Configurations/BranchDetails";
import Reports from "../views/Reports";
import AppLayout from "../views/AppLayout";
import LoanTypeDetail from "../views/Configurations/LoanTypes/LoanTypesDetails";
import TermssAndConditions from "../shared/components/TermsAndConditions";
import LoanindividualDetails from "../views/Loans/LoanIndividualDetails";
import Repayments from "../views/Repayments";
import forgetPasswordForm from "../views/Auth/ForgotPassword";
import VerifyOTP from "../views/Auth/VerifyOTP";
import CreatePassword from "../views/Auth/CreatePassword";
import ResetPassword from "../views/Auth/ResetPassword";
import CTVerificationLoan from "../views/CTVerification/CTVerificationLoan";
import VelichamLoan from "../views/VelichamLoan";
import PaymentRedirection from "../views/VelichamLoan/PaymentRedirection";
import AccountantHome from "../views/Accountant/AccountantLoans";
import BranchAccountant from "../views/BranchAccountant";
import PipelineWrapper from "../views/Pipeline/PipelineWrapper";
import DisbursementWrapper from "../views/Disburesement/DisbursementWrapper";
import IndividualKeyFactor from "../views/CreditReport/KeyFactors/IndividualKeyFactor";
import LoanClaimsWrapper from "../views/LoanClaims/Wrapper";
import IndividualLoanClaim from "../views/Loans/LoanIndividualDetails/LoanClaims";
import MODDocuments from "../views/MODDocuments/MODWrapper";
import CrifReportComponent from "../views/CreditReport/CreditReportWrapper";
import PaymentReceipt from "../views/VelichamLoan/PaymentReceipt";
import PayuView from "../views/Payu";

const AppRouter = () => {
  const unAuthenticatedRoutes: RouterProps[] = [
    {
      path: AppRoutes.LOGIN,
      element: Login,
    },
    {
      path: AppRoutes.FORGOT_PASSWORD,
      element: forgetPasswordForm,
    },
    {
      path: AppRoutes.RESET_PASSWORD,
      element: ResetPassword,
    },
    {
      path: AppRoutes.VERIFY_OTP,
      element: VerifyOTP,
    },
    {
      path: AppRoutes.CREATE_PASSWORD,
      element: CreatePassword,
    },
    {
      path: AppRoutes.TERMS_AND_CONDITIONS,
      element: TermssAndConditions,
    },
    {
      path: AppRoutes.VELICHAM_LOAN,
      element: VelichamLoan,
    },
    {
      path: AppRoutes.PAYMENT_REDIRECTION,
      element: PaymentRedirection,
    },
    {
      path: AppRoutes.PAYMENT_RECEIPT,
      element: PaymentReceipt
    },
    {
      path: AppRoutes.CCAVENUE,
      element: PayuView
    },
    {
      path: AppRoutes.PAYU,
      element: PayuView
    }
  ];
  const authenticatedRoutes: RouterProps[] = [
    {
      path: AppRoutes.PROFILE,
      element: Profile,
    },
    {
      path: AppRoutes.DASHBOARD,
      element: Dashboard,
    },
    {
      path: AppRoutes.USERS,
      element: Users,
    },
    {
      path: AppRoutes.USER_DETAILS,
      element: UserDetails,
    },
    {
      path: AppRoutes.CUSTOMERS,
      element: Customers,
    },
    {
      path: AppRoutes.CUSTOMERS_LOAN_DETAILS,
      element: LoanindividualDetails,
    },
    {
      path: AppRoutes.CT_VERIFICATION_LOAN_DETAILS,
      element: LoanindividualDetails,
    },
    {
      path: AppRoutes.CUSTOMERS_DETAIL,
      element: CustomerDetail,
    },

    {
      path: AppRoutes.DISBURSEMENT,
      element: DisbursementWrapper,
    },
    {
      path: AppRoutes.LOANS,
      element: Loans,
    },
    {
      path: AppRoutes.PIPELINE_DETAILS,
      element: LoanindividualDetails,
    },

    {
      path: AppRoutes.LOAN_INDIVIDUAL_DETAIL,
      element: LoanindividualDetails,
    },
    {
      path: AppRoutes.DISBURSEMENT_DETAILS,
      element: LoanindividualDetails,
    },
    {
      path: AppRoutes.REPAYMENTS,
      element: Repayments,
    },
    {
      path: AppRoutes.CT_VERIFICATION,
      element: Loans,
    },
    {
      path: AppRoutes.CT_VERIFICATION_DETAILS,
      element: CustomerDetail
    },
    {
      path: AppRoutes.CONFIGURATIONS,
      element: Configuration,
    },
    {
      path: AppRoutes.BRANCH_DETAIL,
      element: BranchDetails,
    },
    {
      path: AppRoutes.REPORTS,
      element: Reports,
    },
    {
      path: AppRoutes.LOAN_TYPE_DETAIL,
      element: LoanTypeDetail,
    },
    {
      path: AppRoutes.PIPELINE,
      element: PipelineWrapper,
    },
    {
      path: AppRoutes.CT_VERIFICATION_LOAN,
      element: CTVerificationLoan,
    },
    {
      path: AppRoutes.ACCOUTANT_HOME,
      element: AccountantHome
    },
    {
      path: AppRoutes.BRANCH_ACCOUNTANT_HOME,
      element: BranchAccountant
    },
    {
      path: AppRoutes.INDIVIDUAL_CUSTOMER_CREDIT_REPORT,
      element: CrifReportComponent
    },
    {
      path: AppRoutes.INDIVIDUAL_CUSTOMER_CREDIT_REPORT_KEY_FACTOR,
      element: IndividualKeyFactor
    },
    {
      path: AppRoutes.LOAN_CLAIMS,
      element: LoanClaimsWrapper
    },
    {
      path: AppRoutes.INDIVIDUAL_LOAN_CLAIM,
      element: IndividualLoanClaim
    },
    {
      path: AppRoutes.MOD_DOCUMENTS,
      element: MODDocuments
    },
  ];

  if (Boolean(process.env.REACT_APP_UNDER_DEVELOPMENT)) {
    unAuthenticatedRoutes.push({
      path: appRoutes.APP_COMPONENTS,
      element: AppComponents,
    });
  }

  return (
    <div>
      <Router>
        <Routes>
          {unAuthenticatedRoutes.map(
            ({ element: MyElement, ...route }, index) => (
              <Route key={index} {...route} element={<MyElement />} />
            )
          )}
          <Route path={AppRoutes.HOME} element={isAuthenticated(AppLayout)}>
            {authenticatedRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={isAuthenticated(route.element)}
              />
            ))}
          </Route>
          <Route path="*" element={<Navigate replace to={appRoutes.LOGIN} />} />
        </Routes>
      </Router>
    </div>
  );
};

export default AppRouter;
