import { deserialize, serialize } from "serializr";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import axiosInstance from "../../interceptor/axiosInstance";
import { useState } from "react";
import { Dashboard, DashboardParams } from "../../models/dashboard.model";
import { DashboardGraph } from "../../models/dashboardGraph.model";
import { Graph } from "../../enums/Graph.type";
import { DashboardServiceConstants } from "./constants";

export const DashboardService = () => {
  const [loading, setLoading] = useState(false);
  const [tatLoading, setTatLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const getDashboard = async (params?: DashboardParams) => {
    setLoading(true);

    try {
      const queryParams = serialize(DashboardParams, params || {});
      const { data } = await axiosInstance.get(ApiRoutes.DASHBOARD, {
        params: queryParams,
      });
      const dashboardData = deserialize(Dashboard, data?.["dashboard"]);
      return dashboardData;
    } catch (ex) {
      Notification({
        message: "Unable to get dashboard details",
        description: (ex as Error).message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getAdminUserGraph = async (params?: DashboardParams) => {
    setLoading(true);

    try {
      const queryParams = serialize(DashboardParams, params || {});
      const { data } = await axiosInstance.get(ApiRoutes.ADMIN_USER_GRAPH, {
        params: queryParams,
      });
      const graphData = deserialize(
        DashboardGraph,
        data?.["graph"] as DashboardGraph[]
      );
      return graphData;
    } catch (ex) {
      Notification({
        message: "Unable to get graph details",
        description: (ex as Error).message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getDashboardTAT = async (params?: DashboardParams) => {
    setTatLoading(true);
    const queryParams = serialize(DashboardParams, params || {});
    try {
      const { data } = await axiosInstance.get(ApiRoutes.DASHBOARD_TAT, {
        params: queryParams,
      });
      return data?.tat;
    } catch (ex) {
      Notification({
        message: "Unable to get TAT",
        description: (ex as Error).message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setTatLoading(false);
    }
  };

  const getSuperAdminGraph = async (params: DashboardParams, graph: Graph) => {
    setLoading(true);

    const apiRoute =
      graph === Graph.OUTSTANDING
        ? ApiRoutes.SUPER_ADMIN_OUTSTANDING_GRAPH
        : ApiRoutes.SUPER_ADMIN_DISBURSED_GRAPH;
    try {
      const queryParams = serialize(DashboardParams, params);
      const { data } = await axiosInstance.get(apiRoute, {
        params: queryParams,
      });
      const graphData = deserialize(
        DashboardGraph,
        data?.["graph"] as DashboardGraph[]
      );
      return graphData;
    } catch (ex) {
      Notification({
        message: DashboardServiceConstants.GRAPH_ERROR,
        description: (ex as Error).message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getHeadofficerGraph = async (params: DashboardParams, graph: Graph) => {
    setLoading(true);

    const apiRoute =
      graph === Graph.APPLICATION
        ? ApiRoutes.HO_APPLICATION_GRAPH
        : ApiRoutes.HO_APPROVAL_GRAPH;
    try {
      const queryParams = serialize(DashboardParams, params);
      const { data } = await axiosInstance.get(apiRoute, {
        params: queryParams,
      });
      const graphData = deserialize(
        DashboardGraph,
        data?.["graph"] as DashboardGraph[]
      );
      return graphData;
    } catch (ex) {
      Notification({
        message: DashboardServiceConstants.GRAPH_ERROR,
        description: (ex as Error).message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const getMISOfficerGraph = async (params: DashboardParams, graph: Graph) => {
    setLoading(true);
    const apiRoute =
      graph === Graph.APPLICATION
        ? ApiRoutes.APPLICATION_GRAPH
        : ApiRoutes.HO_APPROVAL_GRAPH;
    try {
      const queryParams = serialize(DashboardParams, params);
      const { data } = await axiosInstance.get(apiRoute, {
        params: queryParams,
      });
      const graphData = deserialize(
        DashboardGraph,
        data?.["graph"] as DashboardGraph[]
      );
      return graphData;
    } catch (ex) {
      Notification({
        message: DashboardServiceConstants.GRAPH_ERROR,
        description: (ex as Error).message,
        type: NotificationTypes.ERROR,
      });
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  }

  return {
    getDashboard,
    loading,
    error,
    tatLoading,
    getAdminUserGraph,
    getDashboardTAT,
    getSuperAdminGraph,
    getHeadofficerGraph,
    getMISOfficerGraph
  };
};
