import { ColumnProps } from "antd/lib/table";
import { Loan } from "../../../models/loan.model";
import { fillEmptyData } from "../../../shared/utils/fillEmptyData";
import React from "react";
import { renderModStatus } from "../../../shared/utils/renderModStatus";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import { getFullName } from "../../../shared/utils/getFullName";

export const eSignedColumns: ColumnProps<Loan>[] = [
    {
      key: 1,
      title: "Loan ID",
      dataIndex: "code",
      render: (code: string) => code?.toUpperCase(),
      sorter: true
    },
    {
      key: 2,
      title: "Partner ID",
      dataIndex: "laf",
      render: (laf: string) => fillEmptyData(laf?.toUpperCase()),
    },
    {
      key: 3,
      title: "BC Status Tag",
      dataIndex: "currentStatusTag",
      render: (_, record) => {
        if (!record?.currentStatusTag) return " - "
        const className =
          "active-status--" + record.currentStatusTag?.split("_")?.join("-");
        return (
          <span
            className={`text-success text-capitalize active-status ${className}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {removeUnderscore(record.getBCTagLabel() ?? "")}
          </span>
        );
      },
    },
    {
      key: 4,
      title: "Customer Name",
      dataIndex: "name",
      render: (_, record) => record?.customer?.getName(),
      sorter: true
    },
    {
      key: 5,
      title: "Security",
      dataIndex: "securedType",
      render: (security: string) => renderModStatus(security),
    },
    {
      key: 6,
      title: "Document ID",
      dataIndex: "modDocumentId",
      render: (_, record: Loan) => record?.modDocumentId
    },
    {
      key: 7,
      title: "Center",
      dataIndex: "centerName",
      sorter: true,
    },

    {
      key: 8,
      title: "CRO",
      dataIndex: "createdByFirstName",
      sorter: true,
      render: (_, record) => getFullName(record?.createdBy),
    },
    {
      key: 9,
      title: "Loan Type",
      dataIndex: "loanTypeName",
      sorter: true,
    },
    {
      key: 10,
      title: "Batch Id",
      dataIndex: "batchId",
    },
    {
      key: 11,
      title: "Branch Name",
      dataIndex: "branch",

      render: (_: string, record: Loan) => record?.branch?.name,
    },

    {
      key: 12,
      title: "Total Amount",
      dataIndex: "requestedLoanAmt",
    },
    {
      key: 13,
      title: "Status",
      dataIndex: "status",
      sorter: true,
      render: (_, record) => {
        const className = "active-status--" + record.status?.split("_")?.join("-");
        return (
          <span
            className={`text-success text-capitalize active-status ${className}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {removeUnderscore(record.getLoanStatusLabel() ?? "")}
          </span>
        );
      },
    },
    {
      key: 14,
      title: "Preferred channel",
      dataIndex: "organizationName",
      sorter: true
    },
  ];