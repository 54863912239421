import React from 'react'
import "./payu.scss"
import { PayuConstants } from './constants'
import useQueryParams from '../../shared/hooks/useQueryParams'
import { useLocation } from 'react-router-dom'
import { PAYU } from '../../routes/routeConstants/appRoutes'

const PayuView = () => {
    const { getParams } = useQueryParams({
        params: {}
    })
    const { pathname } = useLocation()
    const getIframeLink = () => 
        pathname.includes(PAYU) 
        ? PayuConstants.PAYU_LINK.concat(getParams().id) 
        : PayuConstants.CC_AVENUE_LINK.concat(getParams().id);
    
    return (
        <div className='payu'>
            <iframe src={getIframeLink()} title={PayuConstants.TITLE} />
        </div>
    )
}

export default PayuView