import { alias, custom, primitive, serializable } from "serializr";

export class FileAttachment {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("attachment_id", primitive()))
  attachmentId?: number;

  @serializable(alias("attachment_upload_id", primitive()))
  attachmentUploadId?: number;

  @serializable(alias("attachment_type", primitive()))
  attachmentType?: string;

  @serializable(alias("attachment_url", primitive()))
  attachmentUrl?: string;
  @serializable(alias("s3_key", primitive()))
  s3Key?: string;

  @serializable(
    alias(
      "attachment",
      custom(
        (file) => file,
        (json) => {}
      )
    )
  )
  attachment?: File;
}

export class DocumentUpload {
  @serializable(alias('front_side_image_id', primitive()))
  frontSideImageId?: number;

  @serializable(alias('document_type', primitive()))
  documentType?: string

  @serializable(alias("loan_agreement_doc_id"))
  loanAgreementDocId?: number

  @serializable(alias("pdc_doc_id"))
  pdcDocId?: number

  @serializable(alias("nach_doc_id"))
  nachDocId?: number
}
