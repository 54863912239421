/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import DashStats from "../DashStats";
import StatsGraph from "../../../shared/components/StatsGraph";
import { AuthContext } from "../../../context/AuthContext";
import { DashboardService } from "../../../services/Dashboard/dashboardService.service";
import "../dashboard.scss";
import CustomRangePicker from "../../../shared/components/CustomRangePicker";
import {
  Dashboard as DashboardModel,
  DashboardParams,
} from "../../../models/dashboard.model";
import { getNCharacters } from "../../../shared/utils/getNCharacters";
import { CardProps } from "../../../shared/types/card.type";
import { User } from "../../../enums/user.type";
import { MetaService } from "../../../services/Meta/meta.service";
import { Meta } from "../../../models/meta.modal";
import { CustomSelect } from "../../../shared/components/CustomSelect";
import useBranch from "../../../shared/hooks/useBranch";
import { genderOptions } from "../../../shared/Constant/genderOption";
import { monthOptions } from "../../../shared/Constant/monthsOptions";
import { DashboardGraph } from "../../../models/dashboardGraph.model";
import userStatusOption from "../../../shared/Constant/userStatusOption";
import { DateRange } from "../../../shared/types/date.type";
import { restrictMonths } from "../../../shared/utils/dateUtils";
import { Moment } from "moment"

interface AdminDashboardProps {
  onRoleChange: (role: User) => void;
  selectedRole?: string;
}

interface Option {
  status?: Meta[];
  role?: Meta[];
  branch?: Meta[];
  region?: Meta[];
  gender?: Meta[];
  month?: Meta[];
}

const AdminDashboard = (props: AdminDashboardProps) => {
  const { onRoleChange, selectedRole } = props;
  const { user } = AuthContext();
  const { getDashboard, getAdminUserGraph, loading } = DashboardService();
  const [dashboardData, setDashboardData] = useState<DashboardModel>();
  const [dashboardGraph, setDashboardGraph] = useState<DashboardGraph[]>([]);
  const [filter, setFilter] = useState<DashboardParams>();
  const [optionLists, setOptionLists] = useState<Option>();

  const { loading: metaLoading, getRegions, getRoles } = MetaService();
  const { branches, handleBranchScroll } = useBranch({});

  useEffect(() => {
    handleGetDashboard({ ...filter, user: selectedRole });
  }, [filter]);

  useEffect(() => {
    initializeMeta();
    handleGetUserGraph();
  }, []);

  const initializeMeta = async () => {
    const [role, region] = await Promise.all([getRoles(), getRegions()]);
    setOptionLists((optionLists) => ({ ...optionLists, role, region }));
  };

  const handleGetDashboard = async (params?: DashboardParams) => {
    const data = await getDashboard(params);
    data && setDashboardData(data);
  };

  const handleGetUserGraph = async ({ fromDate, toDate }: DateRange = {}) => {
    const data = await getAdminUserGraph({
      fromDate,
      toDate,
    });
    data && setDashboardGraph(data);
  };

  const handleFilter = (value: DashboardParams) => {
    setFilter({ ...filter, ...value });
    onRoleChange(value.user as User);
  };

  const restrictMonthsInGraph = (
    current: Moment,
    selectedFromDate: Moment | null,
    maxMonths: number = 11
  ): boolean => {
    return !!selectedFromDate && restrictMonths(current, selectedFromDate, maxMonths);
  };

  const handleGraphData = (): {
    key: string;
    count: number;
    value: number;
  }[] => {

    const months: string[] = (dashboardGraph?.map((graph) => String(graph.month)) || [])

    return dashboardGraph?.length
      ? months.map((key) => ({
        key,
        value:
          (dashboardGraph || []).find(
            (item) => getNCharacters(String(item?.month), 3) === key
          )?.count ?? 0,
        count: 0,
      }))
      : [];
  };

  const card: CardProps[] = [
    {
      label: "Total Users",
      count: dashboardData?.totalUsers,
    },
    {
      label: "Active Users",
      count: dashboardData?.activeUsers,
    },
    {
      label: "Inactive Users",
      count: dashboardData?.inactiveUsers,
    },
    {
      label: "Total Branches",
      count: dashboardData?.totalBranches,
    },
  ];
  const selectFilters = [
    {
      label: "status",
      options: userStatusOption,
      placeholder: "Select Status",
    },
    {
      label: "role",
      options: optionLists?.role,
      placeholder: "Select Role",
    },
    {
      label: "branch",
      options: branches,
      placeholder: "Select Branch",
      loading: metaLoading,
      onScroll: handleBranchScroll,
    },
    {
      label: "region",
      options: optionLists?.region,
      placeholder: "Select Region",
      loading: metaLoading,
      onScroll: handleBranchScroll,
    },
    {
      label: "gender",
      options: genderOptions,
      placeholder: "Select Gender",
    },
    {
      label: "month",
      options: monthOptions,
      placeholder: "Select Month",
    },
  ];
  const handleSelectChange = (name: string, value: string | number) =>
    setFilter({ ...filter, [name]: value });

  return (
    <div className="super-admin dashboard">
      {loading && (
        <div className="spin-overlay">
          <Spin />
        </div>
      )}
      <Row gutter={[30, 16]}>
        <Col span={10}>
          <DashStats
            employee={user?.employee}
            card={card}
            onFilter={handleFilter}
            role={selectedRole}
          />
        </Col>
        <Col span={14}>
          <div className="card mb-5">
            <Row gutter={[20, 20]}>
              {selectFilters?.map((val, index) => {
                return (
                  <Col span={12} key={index}>
                    <div className="mb-5 text-capitalize">{val.label}</div>
                    <CustomSelect
                      key={index}
                      name={val.label}
                      placeholder={val.placeholder}
                      options={val?.options ?? []}
                      onChange={handleSelectChange}
                      loading={val?.loading}
                      onScroll={val?.onScroll}
                      allowClear
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
          <StatsGraph
            title="Users"
            hasCard
            data={handleGraphData()}
            axes={{
              x: "Count",
              y: "Month",
            }}
            barColor="#22efb0"
            extraContent={
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <CustomRangePicker onChange={handleGetUserGraph} disabledDateCondition={restrictMonthsInGraph} />
                </Col>
              </Row>
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default AdminDashboard;
