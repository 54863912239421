export enum GraphType {
  OUTSTANDING = "outstanding",
  DISBURSED = "disbursed",
  LOAN_APPLICATION = "loanApplications",
  LOAN_APPROVALS = "loanApprovals",
}

export enum DashboardGraph {
  LOAN_APPLICATION = "loanApplications",
  LOAN_APPROVALS = "loanApprovals",
}
