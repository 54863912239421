/* eslint-disable react-hooks/exhaustive-deps */
//Icons

//Libraries
import React from "react";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Loan } from "../../../../models/loan.model";
import * as AppRoutes from "../../../../routes/routeConstants/appRoutes";
import Table from "../../../../shared/components/Table";
import { loanColumns } from "./loanColumns";

//Styles
import "./loans.scss";
import ExistingLoanSummary from "../../../Loans/ExistingLoanSummary";
import Back from "../../../../shared/components/Back";
import { PaginationMeta } from "../../../../models/pagination.model";

interface LoansProps {
  searchLoan?: string;
  isCustomerLoan?: boolean;
  isCTVerificationLoan?: boolean;
  aadhaarNumber?: string;
  customerLoans?: Loan[]
  customerLoansMeta?: PaginationMeta
  tableOnChange?: (page: number) => void
  loading?: boolean
}

const Loans = (props: LoansProps) => {
  const location = useLocation();
  //Service

  //Context
  const navigate = useNavigate();
  const { customerId } = useParams<{ customerId: string }>();

  //Prop
  const { isCustomerLoan, isCTVerificationLoan, aadhaarNumber, customerLoans = [], customerLoansMeta, tableOnChange, loading } =
    props;

  const isCTVerification = location?.pathname.includes(
    AppRoutes.CT_VERIFICATION
  );

  return (
    <div className="loans">
      {isCTVerificationLoan && <Back />}
      <ExistingLoanSummary hasCard={true} aadhaarNumber={aadhaarNumber} />
      <div className="card">
        <div className="block__heading">Existing loans</div>
        <Table
          columns={loanColumns}
          dataSource={customerLoans}
          loading={loading}
          pagination={{
            hideOnSinglePage: true,
            total: customerLoansMeta?.totalCount,
            onChange: tableOnChange,
          }}
          onRow={(record) => ({
            onClick: () =>
              navigate(
                generatePath(
                  isCTVerification
                    ? AppRoutes.CT_VERIFICATION_LOAN_DETAILS
                    : AppRoutes.CUSTOMERS_LOAN_DETAILS,
                  {
                    loanId: record?.id,
                    customerId: customerId,
                  }
                ),
                {
                  state: {
                    isCustomerLoan: isCustomerLoan,
                    disbursedDate: record?.recentLoanStatus?.assignedAt,
                  },
                }
              ),
          })}
        />
      </div>
    </div>
  );
};

export default Loans;
