import "./loanSummary.scss";

import React from "react";

import Appliances from "../../../Customers/CustomerDetail/PersonalDetails/Appliances";
import BankDetails from "../../../Customers/CustomerDetail/PersonalDetails/BankDetails";
import BasicEligibility from "./BasicEligibility";
import BusinessNature from "./BusinessNature";
import Documents from "../../../Customers/CustomerDetail/Documents";
import ExistingLoanDetails from "../../../Customers/CustomerDetail/PersonalDetails/ExistingLoanDetails";
import { Loan } from "../../../../models/loan.model";
import LoanDetails from "./LoanDetails";
import { LoanService } from "../../../../services/Loan/loan.service";
import PresentBusinessAsset from "./PresentBusinessAsset";
import { Drawer, Spin } from "antd";
import UserPictures from "./UserPictures";
import { LoanType } from "../../../../enums/loanType.type";
import JobDetails from "./JobDetails";
import ThirdPartyVerification from "./ThirdPartyVerification";
import useDrawer from "../../../../shared/hooks/useDrawer";
import FamilyDetails from "../FamilyDetails";
import FamilyInformation from "./FamilyInformation";
import LoanPersonalWrapper from "../../../Customers/CustomerDetail/PersonalDetails/LoanPersonalWrapper";
import AgriNature from "./AgriNature";
import AgriAlliedNature from "./AgriAlliedNature";
import SecurityDetails from "./SecurityDetails";
import { VivitriDetails } from "../../../../models/vivitriDetails.model";

interface LoanSummaryProps {
  loan: Loan;
  loanLoad?: boolean;
  onSuccess?: () => void;
  initializeLoan: () => void;
  initializeCustomer?: () => void;
  vivitriDetails?: VivitriDetails
}

const LoanSummary = (props: LoanSummaryProps) => {
  const { loading } = LoanService();

  const {
    visible: thirdPartyVisible,
    toggleVisibility: toggleThirdPartyVisibility,
  } = useDrawer({});

  const { loan, loanLoad, onSuccess, initializeCustomer, initializeLoan, vivitriDetails } = props;

  return (
    <div className="loan-summary">
      <div className="loan-summary__content">
        <div className="card">
          {loading || loanLoad ? (
            <div className="text-center">
              <Spin />
            </div>
          ) : (
            <>
              <LoanDetails initializeLoan={initializeLoan} vivitriDetails={vivitriDetails} loan={loan} />
              <BasicEligibility details={loan?.eligibilityDetail} loan={loan} />
              <SecurityDetails loan={loan} initializeCustomer={initializeCustomer} initializeLoan={initializeLoan}/>
              <FamilyDetails
                loan={loan}
                customer={loan?.customer}
                coBorrower={loan?.customer?.getCoBorrower()}
                nominee={loan?.customer?.getNominee()}
              />

              {loan?.customer?.id && (
                <FamilyInformation
                  loan={loan}
                  customer={loan?.customer}
                  coBorrower={loan?.customer?.getCoBorrower()}
                  nominee={loan?.customer?.getNominee()}
                  familyDetail={loan?.customer?.familyDetail}
                  familyMembers={loan?.customer?.familyMembers}
                />
              )}

              <ExistingLoanDetails
                loan={loan}
                familyDetail={loan?.customer?.familyDetail}
                loans={loan?.customer?.existingLoans}
              />
              <Appliances appliances={loan?.customer?.getAppliances()} />
              <LoanPersonalWrapper
                cattles={loan?.customer?.getCattles()}
                investments={loan?.customer?.getInvestments()}
                vehicles={loan?.customer?.getVehicles()}
                properties={loan?.customer?.getOtherProperties()}
              />
              {loan?.loanTypeCode === LoanType.FAMILY_SUPPORT && (
                <JobDetails loan={loan?.businessDetail} />
              )}
              {loan?.loanTypeCode === LoanType.BUSINESS && (
                <>
                  <BusinessNature businessDetail={loan?.businessDetail} />
                  <PresentBusinessAsset businessDetail={loan?.businessDetail} />
                </>
              )}
              {loan?.loanTypeCode === LoanType.AGRI && (
                <>
                  <AgriNature AgriDetail={loan?.businessDetail} />
                </>
              )}
              {loan?.loanTypeCode === LoanType.AGRI_ALLIED && (
                <>
                  <AgriAlliedNature AgriAlliedDetail={loan?.businessDetail}/>
                </>
              )}
              <Documents
                loan={loan}
                initializeLoan={initializeLoan}
                initializeCustomer={initializeCustomer}
                customer={loan?.customer}
                loanType={loan?.loanTypeCode}
                hasCard={false}
                coBorrower={loan?.customer?.getCoBorrower()}
                additionalDetails={loan?.businessDetail}
                nominee={loan?.customer?.getNominee()}
              />
              <BankDetails
                customer={loan?.customer}
                loan={loan}
                onSuccess={onSuccess}
              />
              <UserPictures loan={loan} />
              <p
                className=" text-link loan-summary__verification text-bold"
                onClick={() => toggleThirdPartyVisibility(true)}
              >
                3rd Party Verification Details
              </p>
            </>
          )}
        </div>
      </div>
      <Drawer
        className="loan-summary__drawer"
        placement="right"
        onClose={() => toggleThirdPartyVisibility()}
        open={thirdPartyVisible}
        width="916"
        title="3rd Party Verification Details"
        destroyOnClose
      >
        <ThirdPartyVerification customer={loan?.customer} />
      </Drawer>
    </div>
  );
};

export default LoanSummary;
