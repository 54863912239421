import moment, { Moment } from 'moment';
export const allowPastSevenDays = (date: Moment) => {
  if (!date) return false;
  const today = moment()
  return date.isAfter(today, 'day') || date.isBefore(today.subtract(7, 'days'), 'day');
}

export const restrictMonths = (current: Moment, selectedFromDate: Moment, maxMonths: number) => {
  return current.isBefore(selectedFromDate, 'day') || current.isAfter(selectedFromDate.clone().add(maxMonths, 'months').endOf('month'), 'day');
}
