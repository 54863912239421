import { alias, list, primitive, serializable } from "serializr";
import { QueryParams } from "./query.params.model";

export class DisbursementParams extends QueryParams {
  @serializable(alias("responsible_employee_id", list(primitive())))
  responsibleEmployeeId?: number[] = [];

  @serializable(alias("region_id", list(primitive())))
  regionId?: number[] = [];

  @serializable(alias("is_active", list(primitive())))
  isActive?: boolean[] = [];
}

export class DisbursementModel {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("date", primitive()))
  date?: number;
  @serializable(alias("process", primitive()))
  process?: string;
  @serializable(alias("disburse_amount", primitive()))
  disburseAmount?: number;
  @serializable(alias("maximum_loan_amount", primitive()))
  maximumLoanAmount?: number;

  @serializable(alias("branch_ids", list(primitive())))
  branchIds?: number[];
  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("loan_ids", list(primitive())))
  loanIds?: number[];
  @serializable(alias("loan_type_ids", list(primitive())))
  loanTypeIds?: number[];
  @serializable(alias("branch_code"))
  branchCode?: string;
}
