import { BCStatusTags } from "../../enums/currentStatusTags.type";

export const DisbursementConstants = {
    E_SIGNED_LOANS_LABEL: "E-Signed Loans",
    E_SIGNED_LOANS_HASH: "#e-signed-loans",
    APPROVED_LOANS_LABEL: "Approved Loans",
    APPROVED_LOANS_HASH: "#approved-loans",
    DISBURSEMENT_LOANS_LABEL: "Disbursement in Progress",
    DISBURSEMENT_LOANS_HASH: "#disbursement-in-progress",
    SEARCH_LOAN_PLACEHOLDER: "Search Loan",
    FILTER_DRAWER_WIDTH: "70vw",
    COLUMN_DRAWER_WIDTH: 500,
    COLUMN_OPTIONS_LABEL: "Column Options",
    FILTERS_LABEL: "Filters",
    MOVE_LOANS_TO_DISBURSEMENT: "Move Loans to Disbursement",
    SELECT_LOANS_TO_DISBURSE: "Select Loans to Disburse",
    DISBURSEMENT_CONFIRMATION: "Do you want to move these loans to disbursement in progress?",
    LOAN_REPORT_TEMPLATE_NAME: "export_template"
}

export const blockedVclTags = [
    BCStatusTags.SENT_TO_VCL,
    BCStatusTags.VCL_APPROVED,
    BCStatusTags.READY_TO_SEND_BACK,
    BCStatusTags.VCL_AGREEMENT_UPLOADED,
    BCStatusTags.SENT_TO_VCL_FOR_DISBURSEMENT,
  ];