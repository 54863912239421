/* Auth Routes */
export const AUTH = "/auth";
export const LOGIN = AUTH + "/login";

/* Password */

export const FORGOT_PASSWORD = AUTH + "/forgot-password";
export const RESET_PASSWORD = AUTH + "/reset-password";

/* OTP */
export const VERIFY_OTP = AUTH + "/verify-otp";

/* CREATE PASSWORD */
export const CREATE_PASSWORD = AUTH + "/create-password";

/* Home Route */
export const HOME = "/home";

// Profile
export const PROFILE = HOME + "/profile";
// Terms and Conditions
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";

// Dashboard
export const DASHBOARD = HOME + "/dashboard";

// CT - Verification
export const CT_VERIFICATION = HOME + "/ct-verification";
export const CT_VERIFICATION_DETAILS = `${CT_VERIFICATION}/:loanId`;
export const CT_VERIFICATION_LOAN_DETAILS = `${CT_VERIFICATION}+/:customerId/loans/:loanId`;
export const CT_VERIFICATION_LOAN =
  HOME + `${CT_VERIFICATION}+/:customerId/loans`;

// Users
export const USERS = HOME + "/users";
export const USER_DETAILS = `${USERS}/:userId`;

// Customers
export const CUSTOMERS = HOME + "/customers";
export const CUSTOMERS_DETAIL = CUSTOMERS + "/:customerId";
export const CUSTOMERS_LOAN_DETAILS = CUSTOMERS + "/:customerId/loans/:loanId";

//Disbursement
export const DISBURSEMENT = HOME + "/disbursement";
export const DISBURSEMENT_DETAILS = HOME + "/disbursement/:loanId";
// Loans
export const LOANS = HOME + "/loans";
export const LOAN_INDIVIDUAL_DETAIL = `${LOANS}/:loanId`;
export const LOAN_PIPELINE_DETAIL = HOME + `/pipeline/:loanId`;
//Repayments
export const REPAYMENTS = HOME + "/repayments";

// Configurations
export const CONFIGURATIONS = HOME + "/configurations";
export const BRANCH_DETAIL = `${CONFIGURATIONS}/branches/:id`;
export const LOAN_TYPE_DETAIL = `${CONFIGURATIONS}/:loanTypeId`;

//Pipeline
export const PIPELINE = HOME + "/pipeline";
export const PIPELINE_DETAILS = `${PIPELINE}/:loanId`;

// Reports
export const REPORTS = HOME + "/reports";

/* Components */
export const APP_COMPONENTS = "/app-components";

/* Velicham Website  */
export const VELICHAM_LOAN = "/pay-emi";
export const PAYMENT_REDIRECTION = "/payment-redirection";
export const PAYMENT_RECEIPT = "/receipt"

export const ACCOUTANT_HOME = HOME + "/accountant"

export const BRANCH_ACCOUNTANT_HOME = HOME + "/branch-accountant"

export const INDIVIDUAL_CUSTOMER_CREDIT_REPORT = `${CT_VERIFICATION}/:loanId/credit-report`
export const INDIVIDUAL_CUSTOMER_CREDIT_REPORT_KEY_FACTOR = `${CT_VERIFICATION}/:loanId/credit-report/:keyFactor`

export const LOAN_CLAIMS = HOME + "/loan-claims"
export const INDIVIDUAL_LOAN_CLAIM = HOME + "/loan-claims/:loanId"
export const MOD_DOCUMENTS = HOME + "/mod-documents"
export const CCAVENUE = "/ccaven"
export const PAYU = "/payu"