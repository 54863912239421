/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Col,
  Drawer,
  Modal,
  Row,
  Spin,
  Timeline,
  Collapse,
  Button,
} from "antd";
import moment from "moment";
import avatarImage from "../../../../Assets/images/img-fallback.png";
import Document from "../Documents/Document";
import CommentForm from "./CommentForm";
import { removeUnderscore } from "../../../../shared/utils/removeUnderscore";
import { getFileExtension } from "../../../../shared/utils/getFileExtension";
import AppLoader from "../../../../shared/components/AppLoader";
import { Comments } from "../../../../models/comments.model";
import { Customer } from "../../../../models/customer.model";
import { LoanService } from "../../../../services/Loan/loan.service";
import { DocumentExtensions } from "../../../../enums/DocumentExtensions.type";
import { useLocation, useParams } from "react-router-dom";
import useDrawer from "../../../../shared/hooks/useDrawer";
import { Loan } from "../../../../models/loan.model";
import { CommentsConstants } from "./constants";
import { SortDirection } from "../../../../enums/sortDirection.type";
import "./comments.scss";
import * as AppRoutes from "../../../../routes/routeConstants/appRoutes";
import { LocalStorageHelper } from "../../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../../enums/localStorage.enum";
import { CollapseIconPosition } from "../../../../enums/collapseIconPosition.enum";
import { DrawerPlacement } from "../../../../enums/drawerPlacement.enum";
import { LoanComments } from "../../../../shared/types/comments.type";

const { Panel } = Collapse;

interface CommentProps {
  customer?: Customer;
  id?: number;
  isCustomer?: boolean;
  onSuccess?: (comment: Comments) => void;
  commentLoanId?: string;
  currentLoan: Loan;
  allComments: Comments[];
}

const Comment = (props: CommentProps) => {
  const { visible: commentFormVisible, toggleVisibility: toggleCommentForm } =
    useDrawer({});
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [loanComments, setLoanComments] = useState<LoanComments[]>([]);
  const location = useLocation();
  const [docModelOpen, setDocModelOpen] = useState(false);
  const [, setDocLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState("");
  const { currentLoan, customer, id, isCustomer } = props;
  const { customerId, loanId } = useParams();
  const { getLoans, getLoan } = LoanService();
  const isCTVerification = location?.pathname.includes(
    AppRoutes.CT_VERIFICATION
  );
  const isImage = (endpoint: string) =>
    getFileExtension(endpoint) !== DocumentExtensions.PDF;

  const toggleDocModal = () => setDocModelOpen((visible) => !visible);

  const handleFileClick = (url: string) => {
    setSelectedFile(url);
    setDocLoading(true);
    toggleDocModal();
  };

  const getCommentsForLoan = async (): Promise<LoanComments[]> => {
    if (props.allComments?.length) {
      return [
        {
          loanId: Number(loanId),
          comments: props.allComments,
          loanCode: currentLoan.code,
          loanTypeName: currentLoan.loanTypeName,
        },
      ];
    } else {
      const loanDetails = await getLoan(Number(loanId));
      return loanDetails?.comments
        ? [
            {
              loanId: Number(loanId),
              comments: loanDetails.comments,
              loanCode: loanDetails.code,
              loanTypeName: loanDetails.loanTypeName,
            },
          ]
        : [];
    }
  };

  const getCommentsForCustomer = async (): Promise<LoanComments[]> => {
    const loans = await getLoans({
      customerId: [Number(customer?.id || id || customerId)],
      sortBy: CommentsConstants.CREATED_AT,
      sortDirection: SortDirection.ASCEND,
    });

    const loanIds = loans?.loans.map((loan) => loan.id) || [];
    const loanCommentsData = await Promise.all(
      loanIds.map(async (loanId) => {
        const loanDetails = await getLoan(Number(loanId));
        return loanDetails?.comments && loanId
          ? {
              loanId,
              comments: loanDetails.comments,
              loanCode: loanDetails.code,
              loanTypeName: loanDetails.loanTypeName,
            }
          : null;
      })
    ).then((comments) => comments.filter(Boolean) as LoanComments[]);

    return loanCommentsData;
  };

  const initializeLoans = async () => {
    setCommentsLoading(true);

    let loanCommentsData: LoanComments[] = [];

    if (!isCustomer) {
      loanCommentsData = await getCommentsForLoan();
    } else if (isCustomer) {
      loanCommentsData = await getCommentsForCustomer();
    }

    setLoanComments(loanCommentsData);
    setCommentsLoading(false);
  };

  const userAccess = LocalStorageHelper.getItem(
    LocalStorage.CURRENT_USER_ACCESS
  );

  useEffect(() => {
    initializeLoans();
  }, []);

  const handleImageOnLoad = () => {
    setDocLoading(false);
  };

  return (
    <div className="comments">
      {commentsLoading ? (
        <Spin className="text-center" />
      ) : (
        <Row className="card mb-5">
          <Col span={12} className="block__heading">
            {CommentsConstants.ALL_COMMENTS}
          </Col>
          {isCTVerification && (
            <Col span={12} className="text-right">
              {userAccess?.writeAccess && (
                <Button
                  className="loan-disburse-btn ml-5"
                  onClick={() => toggleCommentForm(true)}
                >
                  {CommentsConstants.ADD_COMMENTS}
                </Button>
              )}
            </Col>
          )}
          <Col span={24}>
            <Collapse expandIconPosition={CollapseIconPosition.END} accordion>
              {loanComments.map(
                ({ loanId, comments, loanCode, loanTypeName }) => (
                  <Panel
                    header={
                      <div>
                        {loanCode}{" "}
                        <span className="comments__loanTypeName">
                          {loanTypeName}
                        </span>
                      </div>
                    }
                    key={loanId}
                  >
                    <Timeline>
                      {comments?.length ? (
                        comments.map((val) => (
                          <Timeline.Item
                            key={val.id}
                            color={CommentsConstants.TIME_LINE_COLOR}
                          >
                            <h3 className="text-heading mb-5">
                              {moment(val?.createdAt).format(
                                CommentsConstants.TIMELINE_DATE_FORMAT
                              )}
                            </h3>
                            <div className="content-box">
                              <Row className="mb-5">
                                <Col span={6}>
                                  <span className="block__label">
                                    {CommentsConstants.SUBMITTED_BY}{" "}
                                    {val?.creatorType}:
                                  </span>
                                  <Row className="mt-5">
                                    <Col span={5}>
                                      <img
                                        className="user-img mt-2"
                                        src={avatarImage}
                                        alt={CommentsConstants.AVATAR_ALT}
                                      />
                                    </Col>
                                    <Col span={14}>
                                      <span className="ml-5 block__value">
                                        {`${val?.creatorFirstname ?? ""} ${
                                          val?.creatorLastname ?? ""
                                        }`}
                                      </span>
                                      <div className="block__label ml-5">
                                        {CommentsConstants.ID}
                                        {val?.creatorId}
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                                {val?.creatorBranch && (
                                  <Col span={4}>
                                    {CommentsConstants.BRANCH}
                                    <div className="block__value">
                                      {val?.creatorBranch}
                                    </div>
                                  </Col>
                                )}
                                {val?.createdAt && (
                                  <Col span={7}>
                                    {CommentsConstants.DATE}
                                    <div className="block__value">
                                      {moment(val?.createdAt).format(
                                        CommentsConstants.CREATED_AT_DATE_FORMAT
                                      )}
                                    </div>
                                  </Col>
                                )}
                              </Row>
                              <Row>
                                <Col span={6} className="block__value">
                                  {removeUnderscore(val?.step ?? "")}
                                </Col>
                              </Row>
                              <Row className="mt-2 mb-5">
                                <Col span={24} className="block__label">
                                  {val?.description ?? ""}
                                </Col>
                              </Row>
                              {val?.attachment?.s3Key && (
                                <Col span={24}>
                                  <Document
                                    title={""}
                                    onClick={handleFileClick}
                                    frontImg={{
                                      url: val?.attachment?.s3Key,
                                    }}
                                  />
                                </Col>
                              )}
                            </div>
                          </Timeline.Item>
                        ))
                      ) : (
                        <span className="text-danger">
                          {CommentsConstants.NO_COMMENTS_AVAILABLE}
                        </span>
                      )}
                    </Timeline>
                  </Panel>
                )
              )}
            </Collapse>
          </Col>
        </Row>
      )}
      <Modal
        open={docModelOpen}
        cancelButtonProps={{ className: "modal-footer__cancel" }}
        onCancel={toggleDocModal}
        footer={false}
        bodyStyle={{ height: CommentsConstants.HEIGHT }}
        width={CommentsConstants.WIDTH}
        className="documents-modal"
        destroyOnClose
      >
        {isImage(selectedFile) ? (
          <>
            <AppLoader loading={false} />
            <img
              src={selectedFile}
              onLoad={handleImageOnLoad}
              alt={CommentsConstants.IMAGE_ALT}
            />
          </>
        ) : (
          <iframe src={selectedFile} title={CommentsConstants.IFRAME_TITLE} />
        )}
      </Modal>
      <Drawer
        placement={DrawerPlacement.RIGHT}
        onClose={() => toggleCommentForm(false)}
        open={commentFormVisible}
        width={CommentsConstants.DRAWER_WIDTH}
        title={CommentsConstants.ADD_COMMENT}
        destroyOnClose
      >
        <CommentForm
          loanId={String(currentLoan.id)}
          onClose={toggleCommentForm}
          onSuccess={initializeLoans}
        />
      </Drawer>
    </div>
  );
};

export default Comment;
