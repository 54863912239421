
import React from 'react'
import CustomModal from '../../../shared/components/CustomModal'
import { Form, Formik, FormikHelpers } from 'formik'
import { DownloadReport } from '../../../models/downloadReport.model'
import { modDocumentsConstants } from './constants' 
import SelectInput from '../../../shared/components/SelectInput'
import { Button, Col, Row } from 'antd'
import useBranch from '../../../shared/hooks/useBranch'
import DatePickerField from '../../../shared/components/DatePickerField'
import { DatePickerTypes } from '../../../enums/datePicker.type'
import { SelectModes } from '../../../enums/selectModes.type'
import getLocationStatus from '../../../shared/utils/compareDrawerUse'
import { ButtonType, HTMLButtonType } from '../../../enums/buttonType'
import "./downloadReportModal.scss"
import { useLocation } from 'react-router-dom';
import { ModService } from '../../../services/MODOfficer/modofficer.service';
import getModReportParam from './utils' 
import { dateTimeFormatter } from '../../../shared/utils/formatter';
import { DateFormat } from '../../../enums/dateFormat.type';
interface DownloadReportModalProps {
    visible: boolean
    toggleModal: () => void
    reportType: string
}

const DownloadReportModal = (props: DownloadReportModalProps) => {
    const { visible, toggleModal, reportType } = props
    const location = useLocation();
    const { isPending } = getLocationStatus(
        location.hash
    );
    const { downloadModReport } = ModService()

    const {
        loading: branchesLoading,
        branches,
    } = useBranch({ allBranch: true });

    const handleSubmit = async (data: DownloadReport, formikHelpers: FormikHelpers<DownloadReport>) => {
        const response = await downloadModReport({
          ...data,
          date: undefined,
          reportType: getModReportParam(location.hash)
        });

        if (response) {
            formikHelpers.resetForm()
            toggleModal()
        }
      };

    return (
        <div>
            <Formik onSubmit={handleSubmit} initialValues={new DownloadReport()}>
                {({ setFieldValue }) => {

                    const handleDateChange = (date: string[]) => {
                        setFieldValue(
                            modDocumentsConstants.FORM_CONSTANTS.FROM_DATE,
                            dateTimeFormatter(date[0], DateFormat.ORIGINAL_DATE)
                        );
                        setFieldValue(
                            modDocumentsConstants.FORM_CONSTANTS.TO_DATE,
                            dateTimeFormatter(date[1], DateFormat.ORIGINAL_DATE)
                        );
                        setFieldValue(
                            modDocumentsConstants.FORM_CONSTANTS.DATE.NAME,
                            `${dateTimeFormatter(date[0], DateFormat.ORIGINAL_DATE)}-${dateTimeFormatter(date[1], DateFormat.ORIGINAL_DATE)}`
                        );
                    };
                    return (
                        <CustomModal className='download-report-modal' open={visible} onCancel={toggleModal} title={modDocumentsConstants.MODAL_TITLE(reportType)} isOkButton={false} isCancelButton={false}>
                            <Form className='ant-form-vertical'>
                                <Row>
                                    <Col span={24}>
                                        <SelectInput mode={SelectModes.MULTIPLE} loading={branchesLoading} placeholder={modDocumentsConstants.FORM_CONSTANTS.BRANCH.PLACEHOLDER} label={modDocumentsConstants.FORM_CONSTANTS.BRANCH.LABEL} name={modDocumentsConstants.FORM_CONSTANTS.BRANCH.NAME} options={branches} />
                                    </Col>
                                    {!isPending &&
                                        <Col span={24}>
                                            <label className='ant-form-item-label'>{modDocumentsConstants.FORM_CONSTANTS.DATE.LABEL}</label>
                                            <DatePickerField onChange={handleDateChange} type={DatePickerTypes.RANGE_PICKER} name={modDocumentsConstants.FORM_CONSTANTS.DATE.NAME} placeholder={modDocumentsConstants.FORM_CONSTANTS.DATE.PLACEHOLDER} />
                                        </Col>
                                    }
                                </Row>
                                <div className="drawer-footer text-right">
                                    <Button
                                        className="drawer-footer__submit-button download-button"
                                        htmlType={HTMLButtonType.RESET}
                                        onClick={toggleModal}
                                        type={ButtonType.DEFAULT}
                                    >
                                        {modDocumentsConstants.CANCEL_BUTTON}
                                    </Button>
                                    <Button
                                        className="drawer-footer__submit-button download-button ml-4"
                                        htmlType={HTMLButtonType.SUBMIT}
                                        type={ButtonType.LINK}
                                    >
                                        {modDocumentsConstants.SUBMIT_BUTTON}
                                    </Button>
                                </div>
                            </Form>
                        </CustomModal>
                    )
                }}
            </Formik>
        </div>
    )
}

export default DownloadReportModal