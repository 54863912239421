import {
  alias,
  list,
  object,
  optional,
  primitive,
  serializable,
} from "serializr";

import { BusinessDetail } from "./businessDetail.model";
import { CreatedBy } from "./createdBy.model";
import { Customer } from "./customer.model";
import { EligibilityDetail } from "./eligibilityDetail.model";
import { Group } from "./group.model";
import { LoanStatus } from "../enums/loanStatus.type";
import { QueryParams } from "./query.params.model";
import { getLoanStatusLabel } from "../shared/utils/getLoanStatusLabel";
import { ExistingLoan } from "./existingLoan.model";
import { Comments } from "./comments.model";
import { LoanStatusModel } from "./loanStatus.model";
import { Branch } from "./branch.model";
import { Verification } from "./verification.model";
import { LoanAmountDeductions } from "./DeductionAmount.model";
import { BCStatusTags } from "../enums/currentStatusTags.type";
import { getBCStatusLabelSwitch } from "../shared/utils/getBCStatusLabel";
import { CrifReport } from "./creditReport.model";
import { MODStatus } from "../enums/modStatusTags.type";
import { getMODStatusLabel } from "../shared/utils/getModStatusLabel";
import { ReportNameType } from "../enums/reportName.type";

export class LoanParams extends QueryParams {
  @serializable(alias("customer_id", optional(list(primitive()))))
  customerId?: number[] = [];

  @serializable(alias("status", optional(list(primitive()))))
  status?: string[] = [];

  @serializable(alias("branch_id", optional(list(primitive()))))
  branchId? = [];
  @serializable(alias("center_id", optional(list(primitive()))))
  centerId? = [];

  @serializable(alias("applied_from_date", primitive()))
  appliedFromDate?: string;

  @serializable(alias("applied_to_date", primitive()))
  appliedToDate?: string;

  @serializable(alias("loan_type_id", optional(list(primitive()))))
  loanTypeId? = [];

  @serializable(alias("created_by_id", optional(list(primitive()))))
  createdById? = [];
  @serializable(alias("organisation_ids", optional(list(primitive()))))
  organisations? = [];
  @serializable(primitive())
 rejected?: boolean;
  @serializable(alias("offline_loan", primitive()))
  offlineLoan?: boolean;
  @serializable(alias("rejection_reason", primitive()))
  rejectionReason?: string;
  @serializable(alias("from_date", primitive()))
  fromDate?: string;
  @serializable(alias("to_date", primitive()))
  toDate?: string;
  @serializable(alias("disbursed_from_date", primitive()))
  disbursedFromDate?: string;
  @serializable(alias("disbursed_to_date", primitive()))
  disbursedToDate?: string;
  @serializable(alias("approved_to_date", primitive()))
  approvedToDate?: string;
  @serializable(alias("approved_from_date", primitive()))
  approvedFromDate?: string;
  @serializable(alias("submitted_to_date", primitive()))
  submittedToDate?: string;
  @serializable(alias("submitted_from_date", primitive()))
  submittedFromDate?: string;
  @serializable(alias("pipeline", primitive()))
  pipeline?: boolean;
  @serializable(alias("approved", primitive()))
  approved?: boolean;
  @serializable(alias("ct_verification", primitive()))
  ctVerification?: boolean;
  @serializable(alias("disbursed", primitive()))
  disbursed?: boolean;
  @serializable(alias('security_type', primitive()))
  securityType?: string
  @serializable(alias('mod_status', primitive()))
  modStatus?: string
  @serializable(primitive())
  secured?: string;
  @serializable(alias('un_secured', primitive()))
  unSecured?: string;
  @serializable(alias("active_loans", primitive()))
  activeLoans?: boolean;

  @serializable(alias('security_types', list(primitive())))
  securityTypes?: string[] = [];
  @serializable(alias('channel_type', primitive()))
  channelType?: string;
  
  @serializable(alias('minimum_loan_amount', primitive()))
  minimumLoanAmount?: number;
  
  @serializable(alias('maximum_loan_amount', primitive()))
  maximumLoanAmount?: number;
  
  @serializable(alias('total_amount', primitive()))
  totalAmount?: number;
  
  @serializable(alias('disbursement_date', primitive()))
  disbursementDate?: string;
  
  @serializable(alias('is_hypothecator', primitive()))
  isHypothecator?: boolean;
  
  @serializable(alias('hypothecator_id', list(primitive())))
  hypothecatorId? = []
  
  @serializable(list(primitive()))
  tenure?: number[] = []
  @serializable(list(primitive()))
  hypothercators?: string[] = [];
  
  @serializable(alias('disbursement_from_date', primitive()))
  disbursementFromDate?: string
  
  @serializable(alias('disbursement_to_date', primitive()))
  disbursementToDate?: string
  @serializable(alias('state_id', list(primitive())))
  stateId?: string[] = []

  @serializable(alias("ct_verified_from_date"))
  ctVerifiedFromDate?: string

  @serializable(alias("ct_verified_to_date"))
  ctVerifiedToDate?: string
}
export class LoanReportParams {
  @serializable(alias("report_name", primitive()))
  reportName?: ReportNameType;
  @serializable(alias("date", primitive()))
  date?: string;
  @serializable(list(primitive()))
  branch?: number[] = []
  @serializable(alias("loan_type", primitive()))
  loanType?: number;
  @serializable(alias("organisation", primitive()))
  organisation?: number;

  @serializable(alias("end_date", primitive()))
  endDate?: string;
  @serializable(alias("customer_id", primitive()))
  customerId?: string;
  @serializable(alias("template_name", primitive()))
  templateName?: string;
  @serializable(alias("filename_name", primitive()))
  fileName?: string;

  @serializable(alias("loan_ids", list(primitive())))
  loanIds?: number[];

  @serializable(alias('as_on_date', primitive()))
  asOnDate?: string

  @serializable(primitive())
  hypothecator?: string

  @serializable(alias('from_date'))
  fromDate?: string

  @serializable(alias('to_date'))
  toDate?: string

  @serializable(alias("batch_code"))
  batchCode?: string
}

export class ClosedDateObject {
  @serializable(alias("assigned_at", primitive()))
  assignedAt?: string

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("loan_id", primitive()))
  loanId?: string;

  @serializable(alias("loan_status", primitive()))
  loanStatus?: LoanStatus;

  @serializable(alias("updated_at", primitive()))
  updatedAt?: string;
}

export class Loan {

  @serializable(list(primitive()))
  tenure?: number[] = []

  @serializable(alias("bm_picture_id", primitive()))
  bmPictureId?: number;

  @serializable(alias("active_loans", primitive()))
  activeLoans?: boolean;

  @serializable(alias('total_loan_amount', primitive()))
  totalLoanAmount?: number;

  @serializable(alias('total_loan_count', primitive()))
  totalLoanCount?: number;

  @serializable(alias("loan_deductions", list(object(LoanAmountDeductions))))
  loanDeductions?: LoanAmountDeductions[] = []

  @serializable(alias("doc_officer", primitive()))
  documentationOffier?: string

  @serializable(primitive())
  description?: string;
  
  @serializable(alias("comments", list(object(Comments))))
  comments: Comments[] = [];

  @serializable(alias("bm_picture_url", primitive()))
  bmPictureUrl?: string;
  @serializable(alias("bm_name", primitive()))
  bmName?: string;

  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("laf", primitive()))
  laf?: string;
  @serializable(alias("date", primitive()))
  date?: string;

  @serializable(alias("code", primitive()))
  code?: string;
  @serializable(alias("loan_type_code", primitive()))
  loanTypeCode?: string;

  @serializable(alias("center_id", primitive()))
  centerId?: number;

  @serializable(alias("center_name", primitive()))
  centerName?: string;

  @serializable(alias("created_by", object(CreatedBy)))
  createdBy = new CreatedBy();
  @serializable(alias("applied_date", primitive()))
  appliedDate?: string;
  @serializable(alias("created_by_id", primitive()))
  createdById?: number;
  @serializable(alias("approved_date", primitive()))
  approvedDate?: string;

  @serializable(alias("submitted_at", primitive()))
  submittedAt?: number;
  @serializable(alias("batch_id", primitive()))
  batchId?: number;
  @serializable(alias("branch", object(Branch)))
  branch?: Branch;
  @serializable(alias("loan_type_id", primitive()))
  loanTypeId?: number;

  @serializable(alias("loan_type_name", primitive()))
  loanTypeName?: string;
  @serializable(alias("interest_rate", primitive()))
  interestRate?: string;
  @serializable(alias("tenure_in_months", primitive()))
  tenureInMonths?: string;
  @serializable(alias("monthly_income", primitive()))
  monthlyIncome?: number;
  @serializable(alias("occupation", primitive()))
  occupation?: string;

  @serializable(alias("estimated_cost", primitive()))
  estimatedCost?: number;

  @serializable(alias("requested_loan_amt", primitive()))
  requestedLoanAmt?: number;

  @serializable(alias("currency", primitive()))
  currency?: string;

  @serializable(alias("status", primitive()))
  status?: LoanStatus;

  @serializable(alias("loan_purpose_id", primitive()))
  loanPurposeId?: number;
  @serializable(alias("velicham_emi"))
  velichamEmi?: number;
  @serializable(alias("surplus_after_velicham_emi"))
  surplusAfterVelichamEmi?: number;
  @serializable(alias("loan_cycle"))
  loanCycle?: number;


  @serializable(alias("mas_rejection_reason"))
  masRejectionReason = ""
  
  @serializable(alias("loan_purpose_name", primitive()))
  loanPurposeName?: string;
  @serializable(alias("organisation_ids", list(primitive())))
  organisationIds?: number[];

  @serializable(alias("organisation_id", primitive()))
  organizationId?: number;

  @serializable(alias("organisation_name", primitive()))
  organizationName = ""

  @serializable(alias("collection_date", primitive()))
  collectionDate?: string;

  @serializable(alias("cro_picture_id", primitive()))
  croPictureId?: string;
  @serializable(alias("cro_name", primitive()))
  croName?: string;
  @serializable(alias("ia_name", primitive()))
  coName?: string;

  @serializable(alias("cro_picture_url", primitive()))
  croPictureUrl?: string;
  @serializable(alias("bm_captured_time", primitive()))
  bmCapturedTime?: string;
  @serializable(alias("cro_captured_time", primitive()))
  croCapturedTime?: string;
  @serializable(alias("ia_captured_time", primitive()))
  coCapturedTime?: string;

  @serializable(alias("ia_picture_id", primitive()))
  iaPictureId?: string;
  @serializable(alias("disbursed_amount", primitive()))
  disbursedAmount?: number;

  @serializable(alias("ia_picture_url", primitive()))
  iaPictureUrl?: string;
  @serializable(alias("outstanding_amount", primitive()))
  outstandingAmount?: string;
  @serializable(alias("number_of_pending_dues", primitive()))
  numberOfpendingDues?: number;
  @serializable(alias("paid_principal", primitive()))
  paidPrincipal?: number;
  @serializable(alias("expected_start_date", primitive()))
  expectedStartDate?: string;
  @serializable(alias("rejection_reason", primitive()))
  rejectionReason?: string;
  @serializable(alias("bank_score_verified_by", primitive()))
  bankScoreVerifiedBy?: string;
  @serializable(alias("is_bank_score_approved_by_ho", primitive()))
  isBankScoreApprovedByHo?: boolean;

  @serializable(alias('total_deduction', primitive()))
  totalDeductions?:number;

  @serializable(alias("outstanding_principal_amount", primitive()))
  outstandingPrincipalAmount?: number;
  @serializable(alias("current_cro", primitive()))
  currentCro?: string;

  @serializable(alias('pre_close_amount', primitive()))
  preClosedAmount?: number;

  @serializable(alias("pre_closed_amount", primitive()))
  preCloseAmount?: number;

  @serializable(alias('preclosed_date', primitive()))
  preCloseDate?: string

  @serializable(alias("nach_enabled", primitive()))
  nachEnabled?: boolean;

  @serializable(alias("closed_date", object(ClosedDateObject)))
  closedDate =  new ClosedDateObject();
  
  

  @serializable(alias("customer", object(Customer)))
  customer = new Customer();
  @serializable(alias("recent_loan_status", object(LoanStatusModel)))
  recentLoanStatus?: LoanStatusModel;

  @serializable(alias("group", object(Group)))
  group = new Group();

  @serializable(alias("additional_details_json", object(BusinessDetail)))
  businessDetail = new BusinessDetail();
  @serializable(alias("verification", object(Verification)))
  verification?: Verification;

  @serializable(alias("eligibility_detail", object(EligibilityDetail)))
  eligibilityDetail = new EligibilityDetail();
  @serializable(alias("existing_loans", list(object(ExistingLoan))))
  existingLoans: ExistingLoan[] = [];

  @serializable(alias('current_status_tag', primitive()))
  currentStatusTag?: BCStatusTags;

  @serializable(alias('loan_amount', primitive()))
  loanAmount?: number

  @serializable(alias('customer_id', primitive()))
  customerId?: string

  @serializable(alias('created_by_first_name', primitive()))
  createdByFirstName?: string

  @serializable(alias('mod_status', primitive()))
  modStatus?: string

  @serializable(primitive())
  security?: string

  @serializable(alias("secured_type", primitive()))
  securedType?: string

  @serializable(alias('doc_id', primitive()))
  documentId?: string

  @serializable(alias('property_value', primitive()))
  propertyValue?: number

  @serializable(alias('mod_doc_id', primitive()))
  modDocumentId?: string

  @serializable(alias('mod_doc', primitive()))
  modDocumentUrl?: string

  @serializable(alias('mod_documents_reviewed', primitive()))
  modDocumentsReviewed?: boolean

  @serializable(alias('mod_document_location', primitive()))
  modDocumentLocation?: string

  @serializable(alias('mod_return_date', primitive()))
  modReturnDate?: string

  @serializable(alias('release_letter', primitive()))
  releaseLetter?: string

  @serializable(alias("co_borrower_name"))
  coBorrowerName? = ""

  getModStatusLabel = () => {
    if(this.modStatus) {
      const modStatus: MODStatus = this.modStatus as MODStatus
      return getMODStatusLabel(modStatus);
    } else {
      return " - "
    }
    }

  getLoanStatusLabel = () => {
    if (this.status) {
      const loanStatus: LoanStatus = this.status as LoanStatus;
      return getLoanStatusLabel(loanStatus);
    } else {
      return "Status undefined";
    }
  };

  getBCTagLabel = () => {
    if(this.currentStatusTag) {
      const statusTag: BCStatusTags = this.currentStatusTag as BCStatusTags
      return getBCStatusLabelSwitch(statusTag); 
    } else {
      return "Status undefined";
    }
  }
  
}
  export class CreditReportParams extends CrifReport {
    @serializable(alias('loan_id', primitive()))
    loanId?: number

    @serializable(alias('customer_id', primitive()))
    customerId?: number

    @serializable(primitive())
    initiate?: boolean
  }