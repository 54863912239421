import * as Yup from "yup";
import zxcvbn from "zxcvbn";

const isStrongPassword = (password: string | undefined | null): boolean => {
  if (!password) return true;
  const result = zxcvbn(password);
  const commonWords = [
    "password",
    "welcome",
    "admin",
    "qwerty",
    "velicham",
    "bharathi",
    "loan",
    "vgro",
    "abc",
    "1234",
    "pass",
    "Pa$$word",
  ];
  const checks = [
    // zxcvbn score comparison
    result.score < 3,
    commonWords.some((word) =>
      password.toLowerCase().includes(word.toLowerCase())
    ),
    // check for sequential characters like 123, abc
    /(?:012|123|234|345|456|567|678|789|890|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz)/i.test(
      password
    ),
    // common patterns like aaa
    /(.)\1{4,}/.test(password),
  ];
  return !checks.some((check) => check);
};

export const passwordValidation = Yup.object().shape({
  newPassword: Yup.string()
    .required("Password is Required")
    .min(8, "Must be 8 characters or more")
    .matches(/[a-z]+/, "Atleast one lowercase character is required")
    .matches(/[A-Z]+/, "Atleast one uppercase character is required")
    .matches(/[@$!%*#?&]+/, "Atleast one special character is required")
    .matches(/\d+/, "At least one number is required")
    .test(
      "is-strong-password",
      "Password is too weak or uses predictable patterns",
      (value) => isStrongPassword(value)
    ),
  confirmPassword: Yup.string()
    .required("Password confirmation is required!")
    .oneOf([Yup.ref("newPassword"), ""], "Passwords must match!"),
});
