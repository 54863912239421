/* eslint-disable no-sparse-arrays */
//Icons

//Styles
import "../Documents/documents.scss";

//Libraries
import { Button, Col, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { DocumentExtensions } from "../../../../enums/DocumentExtensions.type";
import Modal from "antd/lib/modal/Modal";
import { getFileExtension } from "../../../../shared/utils/getFileExtension";
import { FamilyMember } from "../../../../models/familyMember.model";
import { removeUnderscore } from "../../../../shared/utils/removeUnderscore";
import AppLoader from "../../../../shared/components/AppLoader";
import { BusinessDetail } from "../../../../models/businessDetail.model";
import { LoanType } from "../../../../enums/loanType.type";
import {
  CloseCircleOutlined,
  DownOutlined,
  RightOutlined,
} from "@ant-design/icons";
import CustomDocument from "../../../../shared/components/CustomDocument";

import { DocumentCategory } from "../../../../enums/documents.type";
import { CustomerDetail } from "../../../../models/customerDetail.model";
import { LoanService } from "../../../../services/Loan/loan.service";
import { Loan } from "../../../../models/loan.model";
import { DocumentType } from "../../../../enums/documentType.type";
import AttachmentUpload from "../../../../shared/components/AttachmentUpload";
import { Form, Formik, FormikHelpers } from "formik";
import { modDocumentValidation } from "../../../MODDocuments/MODUploadForm/modFormValidation";
import CustomModal from "../../../../shared/components/CustomModal";
import uploadIcon from "../../../../Assets/images/upload.png";
import {
  filterModLoanDocuments,
  getModLoanDocuments,
} from "../../../../shared/utils/filterModDocs";
import { DocumentUpload } from "../../../../models/fileAttachment.model";
import { modLoansTypes } from "../../../../enums/modLoans.type";
import { ModService } from "../../../../services/MODOfficer/modofficer.service";
import { LocalStorageHelper } from "../../../../shared/utils/localStorageHelper";
import { LocalStorage } from "../../../../enums/localStorage.enum";
import { Employee } from "../../../../models/employee.model";
import { RoleName } from "../../../../enums/roleName.enum";
import { getThumbnailByLabel } from "../../../../shared/utils/getThumbnailByLabel";
import { SortDirection } from "../../../../enums/sortDirection.type";
import { sortDocumentsById } from "../../../../shared/utils/sorter";

interface DocumentsProps {
  loan?: Loan;
  customer?: CustomerDetail;
  initializeLoans: () => void;
  initializeCustomer: () => void;
  hasCard?: boolean;
  coBorrower?: FamilyMember;
  loanType?: string;
  additionalDetails?: BusinessDetail;
  nominee?: FamilyMember;
  isCustomer?: boolean;
}

const CustomerDocument = (props: DocumentsProps) => {
  const {
    customer,
    hasCard = true,
    coBorrower,
    loanType,
    additionalDetails,
    nominee,
    loan,
    initializeLoans,
    initializeCustomer,
    isCustomer = false,
  } = props;

  const [docModelOpen, setDocModelOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedFile] = useState("");
  const toggleDocModal = () => setDocModelOpen((visible) => !visible);
  const [verifySecurityDocuments, setVerifySecurityDocuments] = useState(
    loan?.modDocumentsReviewed
  );
  const isImage = (endpoint: string) =>
    getFileExtension(endpoint) === DocumentExtensions.PDF ? false : true;

  const [currentDocument, setCurrentDocument] = useState<DocumentCategory>();

  useEffect(() => {
    setCurrentDocument(DocumentCategory.BORROWER);
  }, []);

  const handleDocumentVisibility = (doc: DocumentCategory) => {
    !(doc === currentDocument) &&
      setCurrentDocument(doc === currentDocument ? undefined : doc);
  };
  const employee = LocalStorageHelper.getItem(
    LocalStorage.EMPLOYEE
  ) as Employee;
  const isHO = employee.roleName === RoleName.HEAD_OFFICER;
  const { verifyDocuments } = LoanService();
  const [uploadModal, setUploadModal] = useState(false);
  const { createDocument } = ModService();
  const isModLoan =
    loan?.securedType === modLoansTypes.MOD_SECURED ||
    loan?.securedType === modLoansTypes.EQUAITABLE_MORTAGE ||
    loan?.securedType === modLoansTypes.UNSECURED;

  const handleSwitchChange = () => {
    setVerifySecurityDocuments(!verifySecurityDocuments);
    if (loan?.id && verifySecurityDocuments) {
      verifyDocuments(loan.id, false);
    } else if (loan?.id && !verifySecurityDocuments) {
      verifyDocuments(loan.id, true);
    }
  };

  const loanImages = [
    {
      label: "Agri Allied",
      key: LoanType.AGRI_ALLIED,
      value: additionalDetails?.pictureUrl,
    },
    {
      label: "Agriculture ",
      key: LoanType.AGRI,
      value: additionalDetails?.proofOfAgricultureUrl,
    },
    {
      label: "Salary ",
      key: LoanType.FAMILY_SUPPORT,
      value: additionalDetails?.salaryProofUrl,
    },
    {
      label: "Education",
      key: LoanType.EDUCATION_LOAN,
      value: additionalDetails?.identityCardUrl,
    },
  ];
  const handleUploadDocumentModal = async (
    values: DocumentUpload,
    formikHelpers: FormikHelpers<DocumentUpload>
  ) => {
    if (values?.frontSideImageId) {
      await createDocument(
        {
          frontSideImageId: values.frontSideImageId,
          documentType: DocumentType.OTHER_SECURITY,
        },
        loan?.id || 0
      );
      setUploadModal(false);
      initializeLoans();
      initializeCustomer();
    }
  };

  const isPending = loan?.modStatus === modLoansTypes.PENDING;
  const documents = [
    {
      key: DocumentCategory.BORROWER,
      label: "Borrower Documents",
      value: (
        <Row gutter={[0, 30]}>
          {
            <Col span={8}>
              <CustomDocument
                label="Borrower Aaadhaar Front Side"
                src={
                  isCustomer
                    ? customer?.customerDetails?.[0]?.loanCustomerDetail
                      ?.aadhaarFrontImageUrl
                    : loan?.customer?.aadhaarFrontImageUrl
                }
                thumbnailSrc={loan?.customer?.thumbnailDocs?.aadhaarThumb}
                lazyLoad
              />
            </Col>
          }
          <Col span={8}>
            <CustomDocument
              label="Borrower Aaadhaar Back Side"
              src={
                isCustomer
                  ? customer?.customerDetails?.[0]?.loanCustomerDetail
                    ?.aadhaarBackImageUrl
                  : loan?.customer?.aadhaarBackImageUrl
              }
              thumbnailSrc={loan?.customer?.thumbnailDocs?.aadhaarThumb}
              lazyLoad
            />
          </Col>
          <Col span={8}>
            <CustomDocument
              label="Borrower PAN"
              src={
                isCustomer
                  ? customer?.customerDetails?.[0]?.loanCustomerDetail
                    ?.panDocumentUrl
                  : loan?.customer?.panDocumentUrl
              }
              thumbnailSrc={loan?.customer?.thumbnailDocs?.panThumb}
              lazyLoad
            />
          </Col>

          {sortDocumentsById(
            isCustomer
              ? customer?.address?.addressAttachment
              : loan?.customer?.address?.addressAttachment,
            SortDirection.DESCEND
          )?.map((url, index) => {
            return (
              <Col span={8}>
                <CustomDocument
                  key={url.id}
                  label={`${`Address Proof ${index + 1}`}`}
                  src={url?.attachmentUrl}
                  thumbnailSrc={loan?.customer?.thumbnailDocs?.houseImageThumb}
                  lazyLoad
                />
              </Col>
            );
          })}
        </Row>
      ),
    },
    {
      key: DocumentCategory.CO_BORROWER,
      label: "Co-Borrower Documents",
      value: (
        <Row gutter={[0, 30]}>
          {
            <Col span={8}>
              <CustomDocument
                label="Co-Borrower Aaadhaar Front Side"
                src={coBorrower?.aadhaarFrontImageUrl}
                thumbnailSrc={loan?.customer?.thumbnailDocs?.aadhaarThumb}
                lazyLoad
              />
            </Col>
          }
          <Col span={8}>
            <CustomDocument
              label="Co-Borrower Aaadhaar Back Side"
              src={coBorrower?.aadhaarBackImageUrl}
              thumbnailSrc={loan?.customer?.thumbnailDocs?.aadhaarThumb}
              lazyLoad
            />
          </Col>
          <Col span={8}>
            <CustomDocument
              label="Co-Borrower PAN"
              src={coBorrower?.panDocumentUrl}
              thumbnailSrc={loan?.customer?.thumbnailDocs?.panThumb}
              lazyLoad
            />
          </Col>
          {sortDocumentsById(
            coBorrower?.address?.addressAttachment,
            SortDirection.DESCEND
          )?.map((url, index) => {
            return (
              <Col span={8}>
                <CustomDocument
                  key={url.attachmentId}
                  label={`${`Address Proof ${index + 1}`}`}
                  src={url?.attachmentUrl}
                  thumbnailSrc={loan?.customer?.thumbnailDocs?.houseImageThumb}
                  lazyLoad
                />
              </Col>
            );
          })}
        </Row>
      ),
    },
    {
      key: DocumentCategory.NOMINEE,
      label: "Additional Insured Documents",
      value: (
        <Row gutter={[0, 30]}>
          {
            <Col span={8}>
              <CustomDocument
                label="Additional Insured Aaadhaar Front Side"
                src={nominee?.aadhaarFrontImageUrl}
                thumbnailSrc={loan?.customer?.thumbnailDocs?.aadhaarThumb}
                lazyLoad
              />
            </Col>
          }
          <Col span={8}>
            <CustomDocument
              label="Additional Insured Aaadhaar Back Side"
              src={nominee?.aadhaarBackImageUrl}
              thumbnailSrc={loan?.customer?.thumbnailDocs?.aadhaarThumb}
              lazyLoad
            />
          </Col>
          <Col span={8}>
            <CustomDocument
              label="Additional Insured PAN"
              src={nominee?.panDocumentUrl}
              thumbnailSrc={loan?.customer?.thumbnailDocs?.panThumb}
              lazyLoad
            />
          </Col>
          {sortDocumentsById(
            nominee?.address?.addressAttachment,
            SortDirection.DESCEND
          )?.map((url, index) => {
            return (
              <Col span={8}>
                <CustomDocument
                  key={url.attachmentId}
                  label={`${`Address Proof ${index + 1}`}`}
                  src={url?.attachmentUrl}
                  thumbnailSrc={loan?.customer?.thumbnailDocs?.houseImageThumb}
                  lazyLoad
                />
              </Col>
            );
          })}
        </Row>
      ),
    },
    ,
    {
      key: DocumentCategory.BANK,
      label: "Bank Documents",
      value: (
        <Row gutter={[0, 30]}>
          <Col span={8}>
            <CustomDocument
              label="Bank Statement"
              src={
                isCustomer
                  ? customer?.bankDetails[0]?.loanBankDetail
                    ?.passbookOrChequeDocumentUrl
                  : loan?.customer?.bankDetails?.passbookOrChequeDocumentUrl
              }
              thumbnailSrc={loan?.customer?.thumbnailDocs?.bankStatementThumb}
              lazyLoad
            />
          </Col>
        </Row>
      ),
    },
    {
      key: DocumentCategory.LOAN_PROOF,
      label: `Proof of ${removeUnderscore(loanType ?? "")}`,
      value: (
        <Row>
          {loanType === LoanType.BUSINESS
            ? sortDocumentsById(
              additionalDetails?.address?.addressAttachment,
              SortDirection.DESCEND
            )?.map((data, index) => (
              <Col span={8}>
                <CustomDocument
                  key={data.attachmentId}
                  label={`Nature of ${removeUnderscore(
                    loanType ?? ""
                  )} Image ${index + 1}`}
                  src={data?.attachmentUrl}
                  thumbnailSrc={
                    loan?.customer?.thumbnailDocs?.documentDefaultThumb
                  }
                  lazyLoad
                />
              </Col>
            ))
            : loanImages?.map(
              (data) =>
                loanType === data?.key && (
                  <CustomDocument
                    key={data.key}
                    label={`${data?.label} Document`}
                    src={data?.value}
                    lazyLoad
                    thumbnailSrc={getThumbnailByLabel(data?.label, loan?.customer?.thumbnailDocs)}
                  />
                )
            )}
        </Row>
      ),
    },
    {
      key: DocumentCategory.OTHER,
      label: "Documents",
      value: (
        <>
          {isCustomer ? (
            <Row gutter={[0, 50]}>
              {customer?.documents?.map((doc) => (
                <>
                  {filterModLoanDocuments(
                    sortDocumentsById(doc.loanDocuments, SortDirection.DESCEND)
                  ).map((doc) => (
                    <>
                      {doc?.frontSideImageUrl && (
                        <Col span={8} className="mb-5">
                          <CustomDocument
                            key={doc.id}
                            label={`${doc?.getDocumentLabel()} Front Image`}
                            src={doc?.frontSideImageUrl}
                            classname="mb-5"
                            thumbnailSrc={getThumbnailByLabel(
                              doc?.getDocumentLabel(),
                              loan?.customer?.thumbnailDocs
                            )}
                            lazyLoad
                          />
                        </Col>
                      )}
                    </>
                  ))}
                  {filterModLoanDocuments(
                    sortDocumentsById(doc.loanDocuments, SortDirection.DESCEND)
                  ).map((doc) => (
                    <>
                      {doc?.backSideImageUrl && (
                        <Col span={8} className="mb-5">
                          <CustomDocument
                            key={doc.id}
                            label={`${doc?.getDocumentLabel()} Back Image`}
                            src={doc?.backSideImageUrl}
                            classname="mb-5"
                            thumbnailSrc={getThumbnailByLabel(
                              doc?.getDocumentLabel(),
                              loan?.customer?.thumbnailDocs
                            )}
                            lazyLoad
                          />
                        </Col>
                      )}
                    </>
                  ))}
                </>
              ))}
            </Row>
          ) : (
            <Row gutter={[0, 50]}>
              {loan?.customer?.documents && (
                <>
                  {filterModLoanDocuments(
                    sortDocumentsById(
                      loan?.customer?.documents,
                      SortDirection.DESCEND
                    )
                  ).map((doc) => (
                    <>
                      {doc?.frontSideImageUrl && (
                        <Col span={8} className="mb-5">
                          <CustomDocument
                            key={doc.id}
                            label={`${doc?.getDocumentLabel()} Front Image`}
                            src={doc?.frontSideImageUrl}
                            classname="mb-5"
                            thumbnailSrc={getThumbnailByLabel(
                              doc?.getDocumentLabel(),
                              loan?.customer?.thumbnailDocs
                            )}
                            lazyLoad
                          />
                        </Col>
                      )}
                    </>
                  ))}

                  {filterModLoanDocuments(
                    sortDocumentsById(
                      loan?.customer?.documents,
                      SortDirection.DESCEND
                    )
                  ).map((doc) => (
                    <>
                      {doc?.backSideImageUrl && (
                        <Col span={8} className="mb-5">
                          <CustomDocument
                            key={doc.id}
                            label={`${doc?.getDocumentLabel()} Back Image`}
                            src={doc?.backSideImageUrl}
                            classname="mb-5"
                            thumbnailSrc={getThumbnailByLabel(
                              doc?.getDocumentLabel(),
                              loan?.customer?.thumbnailDocs
                            )}
                            lazyLoad
                          />
                        </Col>
                      )}
                    </>
                  ))}
                </>
              )}
            </Row>
          )}
        </>
      ),
    },

    isModLoan
      ? {
        key: DocumentCategory.SECURITY,
        label: "Security Documents",
        value: (
          <div>
            {currentDocument === DocumentCategory.SECURITY && isHO && (
              <Row className="documents-toggle">
                <Col span={2}>
                  <Switch
                    onChange={handleSwitchChange}
                    checked={verifySecurityDocuments}
                  />
                </Col>
                <Col className="block__heading" span={7}>
                  All Documents Reviewed
                </Col>
              </Row>
            )}
            <Row className="documentUpload">
              {isCustomer
                ? sortDocumentsById(
                  customer?.documents,
                  SortDirection.DESCEND
                ).map((customerDoc) => {
                  return sortDocumentsById(
                    getModLoanDocuments(customerDoc?.loanDocuments),
                    SortDirection.DESCEND
                  ).map((doc) => (
                    <Col span={8} className="mb-5" key={doc.id}>
                      <CustomDocument
                        key={doc.id}
                        label={doc.getDocumentLabel()}
                        src={doc.frontSideImageUrl}
                        classname="mb-5"
                        thumbnailSrc={
                          loan?.customer?.thumbnailDocs
                            ?.documentDefaultThumb
                        }
                        lazyLoad
                      />
                    </Col>
                  ));
                })
                : loan?.customer?.documents &&
                loan?.customer?.documents.length > 0 &&
                sortDocumentsById(
                  getModLoanDocuments(loan?.customer?.documents),
                  SortDirection.DESCEND
                ).map((doc) => (
                  <Col span={8} className="mb-5" key={doc.id}>
                    <CustomDocument
                      key={doc.id}
                      label={doc.getDocumentLabel()}
                      src={doc.frontSideImageUrl}
                      classname="mb-5"
                      thumbnailSrc={
                        loan?.customer?.thumbnailDocs?.documentDefaultThumb
                      }
                      lazyLoad
                    />
                  </Col>
                ))}

              {isPending && (
                <Col span={8}>
                  <div
                    className="attachment-upload__input"
                    onClick={() => setUploadModal(true)}
                  >
                    <div className="attachment-upload__content">
                      <img src={uploadIcon} alt="" className="" />
                      <p> Upload Security Document</p>
                    </div>
                  </div>
                  <Formik
                    initialValues={new Loan()}
                    onSubmit={handleUploadDocumentModal}
                    validationSchema={modDocumentValidation}
                  >
                    {({
                      setFieldValue,
                      isValid,
                      dirty,
                      values,
                      resetForm,
                    }) => {
                      const handleCancel = () => {
                        setUploadModal(false);
                        resetForm();
                      };

                      return (
                        <div className="security-mod-form">
                          <CustomModal
                            className="mod-document-modal"
                            title="Upload Security Document"
                            open={uploadModal}
                            isCancelButton={false}
                            isOkButton={false}
                            onClose={() => setUploadModal(false)}
                          >
                            <Form className="ant-form-vertical mt-5">
                              <Row gutter={[0, 30]}>
                                <Col span={24}>
                                  <AttachmentUpload
                                    accept="image/*,application/pdf"
                                    name="frontSideImageId"
                                    placeholder="Upload File"
                                    setFieldValue={setFieldValue}
                                    isLoading={(value) => setLoading(value)}
                                  />
                                  {values.frontSideImageId && !loading && (
                                    <Col className="mt-5" span={8}>
                                      <span className="text-primary mr-5">
                                        File Uploaded
                                      </span>
                                      <CloseCircleOutlined
                                        onClick={() =>
                                          setFieldValue(
                                            "frontSideImageId",
                                            undefined
                                          )
                                        }
                                      />
                                    </Col>
                                  )}
                                </Col>
                                <Col span={24}>
                                  <div className="drawer-footer text-right">
                                    <Button onClick={handleCancel}>
                                      Cancel
                                    </Button>
                                    <Button
                                      htmlType="submit"
                                      disabled={!dirty || !isValid || loading}
                                      loading={false}
                                      type="link"
                                      className="mod-upload-button"
                                      onClick={() =>
                                        handleUploadDocumentModal
                                      }
                                    >
                                      Upload
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </CustomModal>
                        </div>
                      );
                    }}
                  </Formik>
                </Col>
              )}
            </Row>
          </div>
        ),
      }
      : undefined,
  ];

  return (
    <>
      {!hasCard && (
        <Col span={24} className="block__heading mb-5">
          Documents
        </Col>
      )}
      {(isCustomer ? customer?.documents : loan?.customer?.documents)
        ?.length ? (
        <div className={hasCard ? "card" : "block"}>
          <div className="documents">
            <Row gutter={[60, 0]}>
              <Col span={6} className="documents-titles">
                <div className="documents__wrapper">
                  {documents
                    ?.filter((val) => val)
                    .map((val, index) => (
                      <Row
                        className="documents__wrapper-label cursor-pointer"
                        key={val?.key}
                        onClick={() =>
                          val?.key && handleDocumentVisibility(val?.key)
                        }
                      >
                        <Col span={20} className="text-primary">
                          <span
                            className={`${val?.key === currentDocument &&
                              `text-bold text-heading `
                              }`}
                          >
                            <span
                              onClick={() =>
                                val?.key && handleDocumentVisibility(val?.key)
                              }
                              className="cursor-pointer"
                            >
                              {val?.label}
                            </span>
                          </span>
                        </Col>
                        <Col span={4} className="text-right">
                          {val?.key === currentDocument ? (
                            <DownOutlined />
                          ) : (
                            <RightOutlined />
                          )}
                        </Col>
                      </Row>
                    ))}
                </div>
              </Col>

              <Col span={18}>
                {documents?.map(
                  (data) =>
                    data?.key === currentDocument && <div>{data?.value}</div>
                )}
              </Col>
            </Row>
          </div>
          <Modal
            open={docModelOpen}
            cancelButtonProps={{ className: "modal-footer__cancel" }}
            onCancel={toggleDocModal}
            footer={false}
            bodyStyle={{ height: "500px" }}
            width="750px"
            className="documents-modal"
            destroyOnClose
          >
            {isImage(selectedFile) ? (
              <>
                <AppLoader loading={loading} />
                <img
                  alt="selected-file"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  src={selectedFile}
                  onLoad={() => setLoading(false)}
                />
              </>
            ) : (
              <iframe src={selectedFile} title="document" />
            )}
          </Modal>
        </div>
      ) : (
        <div className={hasCard ? "card" : "block"}>
          <div className="text-center text-danger">No document available</div>
        </div>
      )}
    </>
  );
};

export default CustomerDocument;
