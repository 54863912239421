/* eslint-disable react-hooks/exhaustive-deps */
import { Drawer, Table, TableColumnsType, TableProps } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  LoanWallet as LoanWalletModel,
  LoanWalletParams,
  WalletTransactions,
} from "../../../../models/loanWallet.model";

import { QueryParams } from "../../../../models/query.params.model";
import { LoanService } from "../../../../services/Loan/loan.service";
import ColumnOptions from "../../../../shared/components/ColumnOptions";
import useQueryParams from "../../../../shared/hooks/useQueryParams";
import { ToggleDrawer } from "../../../../shared/types/toggleDrawer";

import { LoanWalletColumn } from "./loanWalletColumn";

interface LoanWalletProps {
  settingsVisible?: boolean;
  onSettings?: ToggleDrawer;
  handleWalletBalance: (balance: number) => void;
}

const LoanWallet = (props: LoanWalletProps) => {
  const [filteredColumns, setFilteredColumns] = useState<string[]>([]);
  const [defaultColumns, setDefaultColumns] =
    useState<TableColumnsType<WalletTransactions>>(LoanWalletColumn);

  const { onSettings, settingsVisible, handleWalletBalance } = props;
  const { loanId } = useParams<{ loanId: string }>();
  const [loanWallet, setLoanWallet] = useState<LoanWalletModel>();
  const { params, updateParams } = useQueryParams({
    params: new LoanWalletParams(),
  });

  //service
  const { loading, getLoanWallet } = LoanService();

  const [loanTotalPage, setLoanTotalPage] = useState(1);

  const populateLoanWallet = async (params?: QueryParams) => {
    if (loanId) {
      const loanWallet = await getLoanWallet(loanId, params);
      if (loanWallet) {
        setLoanWallet(loanWallet?.loans);
        handleWalletBalance(loanWallet?.loans?.totalAmount ?? 0);
      }

      if (loanWallet?.meta) setLoanTotalPage(loanWallet?.meta?.totalCount ?? 1);
    }
  };
  const handleChange: TableProps<WalletTransactions>["onChange"] = (
    { current },
    _,
    sorter
  ) => {
    updateParams({ page: current });
  };

  useEffect(() => {
    populateLoanWallet(params);
  }, [params]);

  return (
    <div className="loan-wallet">
      <div className="card">
        <Table
          columns={LoanWalletColumn}
          dataSource={loanWallet?.transactions?.paymentTransactions}
          loading={loading}
          onChange={handleChange}
          pagination={{
            hideOnSinglePage: true,
            total: loanTotalPage,
          }}
        />
        {settingsVisible && onSettings && (
          <Drawer
            placement="right"
            onClose={() => onSettings(false)}
            open={settingsVisible}
            width={500}
            title="Column Options"
            destroyOnClose
          >
            <ColumnOptions
              defaultColumns={defaultColumns}
              filteredColumns={filteredColumns}
              setFilteredColumns={setFilteredColumns}
              onClose={() => onSettings(false)}
              setDefaultColumns={setDefaultColumns}
            />
          </Drawer>
        )}
      </div>
    </div>
  );
};

export default LoanWallet;
