import { SortDirection } from "../../enums/sortDirection.type";

export const sortDocumentsById = <T extends { id?: number }>(
  documents: T[] = [],
  sortOrder: SortDirection
): T[] =>
  documents.sort((a, b) =>
    sortOrder === SortDirection.ASCEND
      ? (a.id ?? 0) - (b.id ?? 0)
      : (b.id ?? 0) - (a.id ?? 0)
  );
